import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import SearchResults from './fragments/SearchResults'
import YearTypeIndexPanel from './YearTypeIndexSidePanel'
import YearMonIndexPanel from './YearMonIndexSidePanel'
import TrackedDocsSidePanel from './TrackedDocsSidePanel'
import RecentDocsSidePanel from './RecentDocsSidePanel'
import { modalId as searchHelpModalTargetId } from './fragments/SearchHelpModal'
import Home from './Home'
import { UserContext } from './context'
import isElectron from 'is-electron'
import { SearchContext } from './contexts/SearchContext'
import Banner from './fragments/Banner'
import { isMobile } from './customhooks/useMobile'
import NavSearchBox from './fragments/NavSearchBox'

const Search = () => {

  const {
    unseenCount,
  } = useContext(UserContext)

  const {
    searching,
    searchMeta,
    searchParams,
    results,
    performSearch,
    toggleTracked,
  } = useContext(SearchContext)

  const mobile = isMobile();

  const history = useHistory()
  const location = useLocation()

  const loadNextChunk = () => {
    performSearch(results.length)
  }


  const isSearching = useMemo(() => history.location.pathname === '/search', [history.location.pathname]);

  // mount effect
  useEffect(() => {
    performSearch()
  }, [performSearch])

  return (
    <div
      id="search-page"
      style={{
        height: 'calc(100% - 60px)',
        width: mobile
          ? '100%'
          : 'calc(100% - 60px)',
        marginLeft: 'auto',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {
        mobile
          ? <NavSearchBox onSearch={() => {
            if (!location.pathname.startsWith('/search')) {
              history.push('/search')
            }
          }} />
          : null
      }
      {
        unseenCount && !Object.keys(searchParams).length
          ? <div
            className='d-flex justify-content-center align-items-center'
            style={{ backgroundColor: 'transparent', padding: '0.5rem' }}>
            <Banner
              type='warning'
              text={`Atentie! ${unseenCount} acte monitorizate au suferit modificari.`}
              cannotClear
              alignCenter
            />
          </div>
          : null
      }
      {
        (mobile ? isSearching : Object.keys(searchParams).length)
          ? <div
            className=""
            style={{
              marginTop: mobile ? 0 : unseenCount ? 30 : 0,
              height: mobile ? 'calc(100% - 70px)' : unseenCount ? 'calc(100% - 30px)' : '100%', // so the header and top margin are removed from 100%
              width: '100%',
              overflowY: 'scroll'
            }}
            id="infiniteScroll"
          >
            <SearchResults
              total={searchMeta.total}
              took={searchMeta.took}
              results={results}
              searching={searching}
              onLoadMore={loadNextChunk}
              toggleTracked={toggleTracked} />
          </div>
          : <Home />
      }
    </div>
  )
}

export default Search
