import React, { useContext } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { modalId as contactFormModalId } from './fragments/ContactModal'

import logo from './img/logo-white-h100.png'
import { UserContext } from './context'

const TopNav = ({ middleComponent, underComponent }) => {

  const { loggedInUser: { name } } = useContext(UserContext)
  const location = useLocation()

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top py-0">
      <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: -1 }}>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 60, left: 0, width: '100%' }}>
            {underComponent}
          </div>
        </div>
      </div>
      {/* <BackButton history={history} /> */}
      {location.pathname === '/' ? <div className="btn btn-home btn-metallic-dark"><i className="fa fa-home"></i></div> : <Link to="/" className="btn btn-home btn-metallic-dark"><i className="fa fa-home"></i></Link>}
      <Link className="navbar-brand d-flex flex-row align-items-center" to="/">
        <div className="ml-3 ml-sm-0 ml-xs-0 d-flex flex-row align-items-center">
          <img src={logo} height="50" width="auto" style={{ height: 50, width: "auto" }} alt="logo" />
        </div>
      </Link>
      {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
      <div className="mr-auto ml-auto" style={{ flexGrow: 0.4 }}>
        {middleComponent}
      </div>
      {/* </div> */}
      <div className="d-flex flex-row align-items-center">

        {/* <li role="presentation" className="nav-item active">
              <div className="nav-link" style={{ cursor: 'pointer' }} onClick={this.logout}>
              Logout <i className="fa fa-sign-out"></i>
              {loggingOut ? <i className="fa fa-refresh fa-spin fa-fw"></i> : null} <div className="small">{name}</div>
              </div>
            </li> */}
        <div className="navbar-text small mr-2 d-lg-block d-xl-block d-none" style={{ color: 'white', fontWeight: 100 }}>
          {/* {loggingOut ? <i className="fa fa-refresh fa-spin fa-fw"></i> : null} */}
          {name}
        </div>
        {/* <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
          </li> */}
        <div>
          <div className="btn btn-profile btn-metallic-dark" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i></div>
          <div className="dropdown-menu" style={{ right: 0, left: 'auto' }}>
            <Link className="dropdown-item" to="/account">Contul meu</Link>
            <div className="dropdown-divider"></div>
            <button className="dropdown-item cursor-hand" type="button" data-toggle="modal" data-target={`#${contactFormModalId}`}> Contacteaza-ne</button>
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" to="/logout">Iesire</Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
