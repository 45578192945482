import React from 'react'

export const modalId = 'search-help-modal'
const SearchHelpModal = () => (
  <div className="modal" tabIndex="-1" role="dialog" id="search-help-modal" aria-hidden="true" style={{ top: '60px', overflowY: 'hidden', height: 'calc(100% - 60px)' }}>
    <div className="modal-dialog modal-lg" role="document" style={{ width: '75%', maxWidth: '75%', height: 'calc(100% - 4.5rem)' }}>
      <div className="modal-content" style={{ height: '100%' }}>
        <div className="modal-header">
          <h5 style={{ alignContent: 'center', textAlign: 'center', fontSize: 14, color: "#F814CD" }} >INSTRUCTIUNI DE
            UTILIZARE PENTRU LEX ONLINE</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ overflowY: 'auto' }}>

          <p>In partea superioară stânga a paginii, sunt disponibile informații despre ultimele noutăti legislative (ultima actualizare), precum și despre
            cele mai noi acte introduse, iar în partea dreaptă a paginii sunt disponibile ultimele acte recent  accesate. Lex Online se actualizează în timp
            real cu monitoarele oficiale publicate, de aceea deviza noastra este: <b>Orice lege găsită la timp!</b></p>

          <h5 style={{ textAlign: 'center', color: "#F814CD" }}> Căutarea inteligentă poate fi utilizata in 2 moduri</h5>
          <h6><u><b>I. Căutare în titlu si text</b></u></h6>
          <h6><u><b>II. Căutare după anumite acte sau grupuri de acte prin limbaj prescurtat</b></u></h6>

          <p>În partea superioară centrală a paginii, este disponibilă căutarea după un număr de act, an act, tip act
            sau monitor oficial număr sau an și un buton pentru căutare avansată</p>
          <div>
            <img alt="search tab" src="./img/user-manual/1.png" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
          </div>
          <p>Exemple: L47/2023,oug 5/2009,lege 12/2006, cod fiscal, cod penal……etc…. <br />
            De exemplu dacă căutati cod fiscal vă afisează linkul către codul fiscal:
          </p>
          <div>
            <img alt="act 1" src="./img/user-manual/2.png" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
          </div>
          <br />
          <p>Apoi click pe link pentru a vă afisa legea codului fiscal cu toate modificările pe text.</p>
          <div>
            <img alt="act 2" src="./img/user-manual/3.png" style={{ width: "100%", height: "100%", objectFit: 'contain' }} />
          </div>
          <p>
            În cadrul unui act compus se evidenţiează foarte uşor modificările suferite de actul iniţial prin
            culorile afişate :
            <li style={{ padding: [0, 0, 0, 0] }}><span style={{ color: 'red' }}>Roşu</span> - abrogat</li>
            <li style={{ padding: [0, 0, 0, 0] }}><span style={{ color: 'green' }}>Verde</span> - în vigoare (modificat)</li>
            <li style={{ padding: [0, 0, 0, 0] }}><span style={{ color: 'black' }}>Negru</span> - în vigoare</li>
            <li style={{ padding: [0, 0, 0, 0] }}><span style={{ color: 'blue' }}>Albastru</span> - temeiul legal în baza căruia s-a făcut modificarea (apare după fiecare articol
              sau aliniat modificat)</li>
          </p>

          <br />

          <p>După afisarea/deschiderea unui act în partea dreaptă gasiti 3 butoane:</p>
          <div>

            <p><img alt="istoric act" src="./img/user-manual/4.png" />Accesand primul buton vi se va afisa istoricul actelor compuse.</p>
          </div>
          <div>
            <p><img alt="legaturi act" src="./img/user-manual/5.png" />Al doilea buton va arata relatiile/legaturile cu alte acte.</p>
          </div>
          <div>
            <p><img alt="monitorizeaza act" src="./img/user-manual/6.png" />Cel de-al treilea buton reprezintă monitorizarea unui act (se poate monitoriza un act
              pentru a vedea modificările survenite ulterior)</p>
          </div>
          <h5 style={{ alignContent: 'center', textAlign: 'center', color: "#F814CD" }} >Pentru butonul “Legislatie”:</h5>
          <h5 style={{ fontWeight: 'bold', alignContent: 'center', textAlign: 'center', fontSize: 18 }}>Căutarea avansată utilizează un sistem de filtrare inteligent după mai multe criterii:</h5>
          <div style={{ textAlign: 'center'}}>
            <img alt="cautare legislatie" src="./img/user-manual/7.png" />
          </div>
          <li>număr act normativ si an act normativ</li>
          <li>număr Monitor Oficial si an Monitor Oficial</li>
          <li>cuvinte existente în text, titlu, titlu si text al actelor normative</li>
          <li>diferite problematici implicite definite în baza de date (tematici, emitent, tipul actului normativ)</li>
          <br />
          <h5 style={{ fontWeight: 'bold', color: "#F814CD" }}><u>1. Căutarea în titlu si text</u></h5>
          <p>La acest tip de cautare cuvintele introduse in casuta ce nu sunt sintagme (vezi mai jos) vor fi cautate in titlul si textul tuturor actelor din baza de date.</p>
          <p>
            Spre exemplu daca se efectueaza o cautare dupa cuvantul <b>'impozit'</b>, rezultatele vor fi toate documentele in care cuvantul 'impozit' si familia sa (impozitare, impozite, etc.) a fost gasit in titlu si/sau text.
          </p>
          <h5 style={{ fontWeight: 'bold', color: "#F814CD" }}><u>2. Căutarea "în text" identifica rezultatele din textul actelor.</u></h5>
          <p>Spre exemplu dacă se efectuează o căutare după cuvantul 'impozit', rezultatele vor fi toate
            documentele în care cuvantul 'impozit' si familia sa (impozitare, impozite, etc.) a fost găsit în text.</p>
          <h5 style={{ fontWeight: 'bold', color: "#F814CD" }}><u>3. Căutarea separata doar în titlu, doar în text si/sau în ambele.</u></h5>
          <p>Acest tip de cautare mai avansata permite cautare separata in titlu, text si in ambele.<br />Pentru delimitarea lor se folosesc urmatoarele marcaje:</p>
          <li>Doar titlu: <b>{`{cuvinte cautate doar in titlu}`}</b></li>
          <li>Doar text: <b>[cuvinte cautate doar in text]</b></li>
          <li>In ambele: <b>cuvinte cautate si in text si in titlu</b>(fara marcaj)</li>
          <br />
          <p>
            Spre exemplu daca se efectueaza o cautare cu textul <b>'{`{tva}`} [impozit] venit'</b>, rezultatele vor fi toate documentele in care cuvantul 'tva' este gasit doar titlu, 'impozit' doar in text si 'venit' in ambele.
          </p>
          <p>
            Rezultatele sunt ordonate descrescator dupa indicele de relevanta.<br />
            In calcularea indicelui de relevanta prioritatile sunt dupa cum urmeaza: exact in titlu si/sau text, cuvant din familie in titlu si/sau text.<br />
            In exemplu cu 'impozit' primele vor fi rezultatele care contin 'impozit' in titlu si sau text, iar apoi cele care contin in titlu si/sau text cuvinte din familie<br />
            La cautarea mai multor cuvinte vor avea indice de relevanta mai mare actele unde cuvintele au fost gasite toate cuvintele in oridinea cautata si cat mai aproape unele de altele, apoi cele in care au fost gasite partial si in ordine, iar apoi cele in care fost gasite partial si din familia fiecarui cuvant
          </p>
          <h5 style={{ fontWeight: 'bold', color: "#F814CD" }}><u>4. Căutarea specifică prin limbaj prescurtat.</u></h5>
          <p>
            La acest tip de cautare motorul de cautare detecteaza anumite sintagme predefinite pentru a gasi rapid documente specifice.
          </p>
          <p>
            De exemplu:<br />
            daca se introduce in casuta <b>'mon1/2004'</b>, rezultatele vor contine toate actele din monitorul oficial nr 1 din 2004<br />
            daca se introduce in casuta <b>'tva lege/2004'</b>, rezultatele vor contine toate actele de tip lege care contin cuvantul 'tva' dupa regulile de cautare in text si titlu din orice lege din 2004<br />
          </p>
          <h6 ><u>Tipuri de sintagme</u></h6>
          <dl>
            <dt>Filtrare dupa monitor cu formatul <b>'mon[nrmonitor]/[anmonitor]'</b></dt>
            <dd>Rezultatele vor contine doar actele din monitorul oficial cu nr si anul specificat. Ex: <b>'mon 1/2017'</b> =&gt; doar actele din monitorul ofical nr 1 din 2017</dd>
            <dt>Filtrare dupa tip act cu formatul <b>'[tipact]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. Ex: <b>'lege hotarare'</b> =&gt; doar legi si hotarari (se pot folosi si prescurtat <b>'l h'</b>)</dd>
            <dt>Filtrare dupa tip si numar cu formatul <b>'[tipact] [nract]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. avand numerele specificate Ex: <b>'lege1 hg3'</b> =&gt; doar legi cu numarul 1 si HG-uri cu numarul 3 (se pot folosi si prescurtat <b>'l1 hg3'</b>)</dd>
            <dt>Filtrare dupa tip, numar si an cu formatul <b>'[tipact][nract]/[anpublicare]'</b></dt>
            <dd>Rezultatele vor contine doar acte de tipul/-rile cautat/-e. avand conbinatia nr/an specificata Ex: <b>'lege1/2003 oug23/2010'</b> =&gt; legea cu numarul 1 din 2003 si ordonanta de urgenta cu numarul 23 din 2010 (se pot folosi si prescurtat <b>'l1/2003 oug23/2010'</b>)</dd>
          </dl>

          <h5 style={{ alignContent: 'center', textAlign: 'center', color: "#F814CD" }} >"Pentru butonul Dosare instanta":</h5>
          <div style={{ textAlign: 'center'}}>
            <img alt="cautare dosare instanta" src="./img/user-manual/8.png" />
          </div>
          <li>Data start dosar</li>
          <li>Data stop dosar</li>
          <li>Data start ultima modificare</li>
          <li>Data stop ultima modificare</li>
          <li>Numărul dosarului</li>
          <li>Obiectul dosarului</li>
          <li>Nume parte</li>
          <li>Institutii</li>

          <h5 style={{ textAlign: 'center', color: "red" }}>Tehnoredactarea si Tipărirea unui "ACT"</h5>
          <div>
            <p><span style={{ color: 'red' }}>Tehnoredactarea</span> unui act, se realizează cu ajutorul butonului Export word.<img alt="export word" src="./img/user-manual/9.png" /></p>
          </div>
          <div>
            <p><span style={{ color: 'red' }}>Tipărirea</span> întregului act sau tipărirea unui paragraf din act se realizează
              cu ajutorul butonului Export Pdf.<img alt="export pdf" src="./img/user-manual/10.png" /></p>
          </div>

          <h5 style={{ alignContent: 'center', textAlign: 'center', color: "red" }} ><b>!!!!!! IMPORTANT !!!!!!</b></h5>
          <h5 style={{ alignContent: 'center', textAlign: 'center', color: "red" }} >Pentru instalarea programului legislativ pe mobil va rugam să accesati fereastra :</h5>

          <div style={{ textAlign: 'center'}}>
            <img alt="instalare aplicatie mobila" src="./img/user-manual/11.png" />
          </div>
          <p>care se regăseste în aplicatia LexOnline în partea superioară dreapta a paginii.</p>
          <br/>
          <p>În partea stângă a paginii LexOnline, se poate ajunge la informațiile și funcțiile disponibile în
          aplicație:</p>
          <div>
            <p><img alt="index" src="./img/user-manual/12.png" />Index</p>
          </div>
          <div>
            <p><img alt="cautare rapida" src="./img/user-manual/13.png" />Căutare Rapida</p>
          </div>
          <div>
            <p><img alt="acte monitorizate" src="./img/user-manual/14.png" />Acte Monitorizate</p>
          </div>
          <div>
            <p><img alt="shortcut lexonline" src="./img/user-manual/15.png" />Crează Shortcut Lex Online</p>
          </div>
          <div>
            <p><img alt="manual utilizare" src="./img/user-manual/16.png" />Manual de utilizare</p>
          </div>
          <div>
            <p>Cu ajutorul pictogramei <img alt="home button" src="./img/user-manual/17.png" /> vizibilă întotdeauna în colțul din stânga al paginii, utilizatorul
            poate reveni la pagina de bun-venit, de oriunde s-ar afla în cadrul aplicației Lex Online</p>
          </div>


        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Am inteles</button>
        </div>
      </div>
    </div>
  </div>

)
export default SearchHelpModal
