import { create } from "apisauce";
import isElectron from "is-electron";

const createApi = (url, authenticatedCallHandler) => {
  let token = localStorage.getItem('token');
  const api = create({
    baseURL: url,
    headers: { Accept: "application/json" },
    withCredentials: true,

  });

  const refreshApi = create({
    baseURL: url,
    headers: { Accept: "application/json" },
    withCredentials: true,
  });

  const origin =
    window.location.protocol === "file:"
      ? `?origin=${encodeURIComponent(window.location.origin + window.location.pathname + '#')}`
      : ''
      // this is here because of monopost (I think) - Seba
      /*: (
        isElectron()
          ? `?origin=${encodeURIComponent(window.location.origin + window.location.pathname + '#')}`
          : '');
      */
  api.axiosInstance.interceptors.request.use((config) => {
    if (token) {
      config.headers = {
        token,
      };
    }
    return config;
  });

  api.axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      console.error(error);
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        // try refreshing the token
        if (token) {
          const { ok, status, headers, originalError } = await refreshApi.get('/api/refresh', null, {
            headers: {
              token,
            },
          })
          if (ok && headers.token) {
            token = headers.token
            localStorage.setItem('token', token)
            originalRequest._retry = true;
            originalRequest.headers.token = token
            return api.axiosInstance(originalRequest)
          } else {
            console.error('Refresh failed', status, originalError)
          }
        }
      }
      throw error
    });

  return {

    clearToken: () => token = null,
    checkAuth: () => {
      return api
        .get("/api/private/userinfo", {})
        .then(({ status, data, ok, problem }) => {
          if (ok) {
            return data;
          }
          throw new Error(data ? data.error : problem);
        })
        .catch((error) => {
          throw error;
        });
    },
    authenticate: async (email, password) => {
      const response = await api.post("/api/login", { email, password });
      const { ok, data, problem, headers } = response
      if (ok) {
        token = headers.token
        if (token) localStorage.setItem('token', token)
        return data;
      }
      throw new Error(data ? data.error : problem);
    },
    logout: async () => {
      const { ok, data, problem } = await api.get("/api/logout", {})
      localStorage.removeItem('token')
      token = null
      if (ok) {
        return true;
      }
      throw new Error(data ? data.error : problem);
    },
    register: ({ nume, email, tel, loc, msg }) => {
      return api.post("/api/register", { nume, email, tel, loc, msg }).then(authenticatedCallHandler);
    },
    sendMessage: ({ msg }) => {
      return api.post("/api/private/send-message", { msg }).then(authenticatedCallHandler);
    },
    downloadMono: () => {
      return api.get("/api/download/monopost", {}, { responseType: 'blob' })
    },
    pdfgenForDoc: (id, hideReds = false) =>
      api
        .get(
          `/api/private/docs/pdfdoc/${id}${hideReds ? (origin ? origin + "&hidereds=1" : "?hidereds=1") : ""}`,
          {},
          { responseType: "arraybuffer" }
        )
        .then(authenticatedCallHandler),
    pdfgenForHTML: (html) =>
      api
        .post("/api/private/docs/pdfhtml", html, { headers: { "Content-type": "text/html" }, responseType: "arraybuffer" })
        .then(authenticatedCallHandler),
    docxgenForDoc: (id, hideReds = false) =>
      api
        .get(
          `/api/private/docs/docx/${id}${hideReds ? (origin ? origin + "&hidereds=1" : " ?hidereds=1") : ""}`,
          {},
          { responseType: "arraybuffer" }
        )
        .then(authenticatedCallHandler),
    docxgenForHTML: (html) =>
      api
        .post("/api/private/docs/docxhtml", html, { headers: { "Content-type": "text/html" }, responseType: "arraybuffer" })
        .then(authenticatedCallHandler),

    getCaseData: (docId, institution) => api.post('/api/private/cases/case', { number: docId, institution }).then(authenticatedCallHandler),

    // index related
    loadIndexByYearAndTypes: () => api.get("/api/private/docs/index-years-types").then(authenticatedCallHandler),
    loadDocTitlesForYearAndType: (year, type, count) =>
      api.post("/api/private/docs/index-years-types-docs", { year, type, count }).then(authenticatedCallHandler),
    loadIndexByYearAndMonitorNumber: () => api.get("/api/private/docs/index-years-mons").then(authenticatedCallHandler),
    loadDocTitlesForYearAndMon: (year, mon_nr, date, count) =>
      api.post("/api/private/docs/index-years-mons-docs", { year, mon_nr, date, count }).then(authenticatedCallHandler),

    loadSearchAggregations: async () => {
      const aggs = await api.get("/api/private/docs/search-aggregations").then(authenticatedCallHandler)
      const caseInstitutions = await api.get('/api/private/cases/get-institutions').then(authenticatedCallHandler);
      return { ...aggs, institutions: caseInstitutions }
    },

    loadDocumentById: (id, useLatestRevisionOnLinks) =>
      api
        .get(`/api/private/docs/doc/${encodeURIComponent(id)}/${useLatestRevisionOnLinks ? "true" : "false"}${origin}${origin.startsWith('file://') ? '&rerouteimgs=false' : ''}`)
        .then(authenticatedCallHandler),

    loadDocumentByLexId: (lexid) =>
      api.get(`/api/private/docs/doclex/${encodeURIComponent(lexid)}${origin}${origin.startsWith('file://') ? '&rerouteimgs=false' : ''}`).then(authenticatedCallHandler),

    search: (filters, start, size, doAggs = true, includeHtmlAndMarkdown = false) => {
      if (filters.isCaseSearch) {
        console.log(filters);
        return api.post("/api/private/cases/search-cases", { ...filters }).then(authenticatedCallHandler);
      } else {
        return api.post("/api/private/docs/search", { filters, start, size, doAggs, includeHtmlAndMarkdown }).then(authenticatedCallHandler);
      }
    },
    // bookmarks related
    getUnseenUpdateCounts: () => api.get(`/api/private/bookmarks/get-unseen-update-counts`).then(authenticatedCallHandler),
    resetBookmarkWhens: () => api.get(`/api/private/bookmarks/reset-bookmark-whens`).then(authenticatedCallHandler),
    markBookmarksSeen: (chainids) => api.post(`/api/private/bookmarks/mark-bookmarks-seen`, { chainids }).then(authenticatedCallHandler),
    loadTrackedDocs: () => api.get(`/api/private/bookmarks/tracked-docs`).then(authenticatedCallHandler),
    startTrackingDocument: (chainid) => api.get(`/api/private/bookmarks/start-tracking-doc/${chainid}`).then(authenticatedCallHandler),
    stopTrackingDocument: (chainid) => api.get(`/api/private/bookmarks/stop-tracking-doc/${chainid}`).then(authenticatedCallHandler),

    // frontpage related
    latest: (daysBack = 10, top = 5) => api.get(`/api/private/docs/latest/${daysBack}/${top}`).then(authenticatedCallHandler),
    recent: (top = 40) => api.get(`/api/private/docs/recent/${top}`).then(authenticatedCallHandler),
    searches: (top = 40) => api.get(`/api/private/searches/${top}`).then(authenticatedCallHandler),

    updateUserPrefs: (newPrefs) => api.post(`/api/private/userprefs`, newPrefs).then(authenticatedCallHandler),
    updateProfileData: (newData) => api.post(`/api/private/profiledata`, newData).then(authenticatedCallHandler),
    changePassword: (currpwd, pwd, pwdconfirm) =>
      api.post(`/api/private/changepwd`, { currpwd, pwd, pwdconfirm }).then(authenticatedCallHandler),
  };
};

export default createApi;
