import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from './context'
import { isMobile } from './customhooks/useMobile'

import { CollapsibleNodeSequence, collapseAll } from './fragments/Collapsibles'
import FullPanel from './fragments/FullPanel'
import cache from './lib/cache'

const YearTypeIndexPanel = () => {

  const mobile = isMobile();
  const { api } = useContext(UserContext);

  const [indexedDocs, setIndexedDocs] = useState(null)
  const [loading, setLoading] = useState(false)

  const CACHE_KEY = 'indexByYearsAndTypes'

  const toggleNode = (siblingsArray, nodeIndex) => {
    const currentCollapseState = siblingsArray[nodeIndex].collapsed
    if (currentCollapseState) //collapse all on the same level if going to uncollapse
      collapseAll(siblingsArray)
    siblingsArray[nodeIndex].collapsed = !currentCollapseState
    cache.set(CACHE_KEY, indexedDocs, 1000 * 60 * 60)
    setIndexedDocs(prev => ([...prev]))
  }

  const toggleLeaf = async (siblingsArray, nodeIndex, loadArgs) => {

    const currentCollapseState = siblingsArray[nodeIndex].collapsed

    if (currentCollapseState) //collapse all on the same level if going to uncollapse
      collapseAll(siblingsArray)

    siblingsArray[nodeIndex].collapsed = !currentCollapseState
    cache.set(CACHE_KEY, indexedDocs, 1000 * 60 * 60)
    const { docs } = siblingsArray[nodeIndex]
    setIndexedDocs(prev => ([...prev]))
    if (docs.length === 0) {
      setLoading(true)
      const dbdocs = await api.loadDocTitlesForYearAndType(...loadArgs)
      siblingsArray[nodeIndex].docs = dbdocs
      cache.set(CACHE_KEY, indexedDocs, 1000 * 60 * 60)
      setIndexedDocs(prev => ([...prev]))
      setLoading(false)
    }
  }

  useEffect(() => {
    const cachedIndexedDocs = cache.get(CACHE_KEY)
    if (!cachedIndexedDocs) {
      setLoading(true)
      api.loadIndexByYearAndTypes().then(decades => {
        cache.set(CACHE_KEY, decades, 1000 * 60 * 60)
        setIndexedDocs(decades)
        setLoading(false)
      })
    } else {
      setIndexedDocs(cachedIndexedDocs)
    }
  }, [api])

  if (!indexedDocs)
    return loading
      ? <i className="fa fa-spinner fa-spin" />
      : <></>

  return (
    <div className={`doc-index ani ${mobile ? 'card home-widget' : ''}`}>
      {
        !mobile
          ? <h5 className="text-left title">Index pe ani</h5>
          : null
      }
      <div className="d-flex flex-column align-items-start children_wrap">
        {indexedDocs.map((child, index) => {

          return (
            // <ul className="children_wrap" key={`root-${index}`}>
            <CollapsibleNodeSequence key={`root-${index}`} {...child} toggleNodeFunction={toggleNode} toggleLeafFunction={toggleLeaf} onClick={() => toggleNode(indexedDocs, index)} />
            // </ul>
          )
        })}

      </div>
    </div>
  )
}

export default YearTypeIndexPanel

export const YearTypeIndexSidePanelModal = (props) => <FullPanel id="yearsTypeIndex" title={<span><i className="fa fa-list"></i> Index pe ani </span>}><YearTypeIndexPanel {...props} /></FullPanel>
