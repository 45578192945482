import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from './context'

const Logout = props => {

  const { logout } = useContext(UserContext)

  const [error, setError] = useState(false)

  useEffect(() => {
    logout().catch(err => {
      console.error(err)
      setError(err)
    })
  }, [logout])

  return (
    <div>
      {error ? error.message : null}
      <i className="fa fa-refresh fa-spin fa-fw"></i>
    </div>
  )
}

export default Logout
