import React, { PureComponent } from 'react'
import autobind from 'react-autobind'

export default class FormModal extends PureComponent {

  constructor(props) {
    super(props)
    const { submitting = false, error = null, fields, initialValues: propsInitialValues, successMessage } = props
      , initialValues = propsInitialValues || Object.assign({}, ...fields.map(f => ({ [f.name]: "" })))
    this.state = {
      fields,
      submitting,
      error,
      successMessage,
      ...initialValues
    }
    // console.log(initialValues)
    autobind(this)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { submitting, error, successMessage } = props
    this.setState({ submitting, error, successMessage })
  }

  onSubmit() {
    const formValues = {}
    this.props.fields.forEach(f => {
      if (this.state[f.name])
        formValues[f.name] = this.state[f.name]
    })
    this.props.onSubmit && this.props.onSubmit(formValues)
  }

  wipe() {
    const values = { submitting: false, error: false, successMessage: null }
      , initialValues = this.props.initialValues || Object.assign({}, ...this.state.fields.map(f => ({ [f.name]: "" })))
    this.props.fields.forEach(f => values[f.name] = initialValues[f.name])
    this.setState(values)
  }
  _handleTextChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { fields, error, submitting, successMessage } = this.state
    return (
      <div className="modal fade" id={this.props.modalId} style={{top: '60px'}}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title || ""}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <span id={`wipe-${this.props.modalId}`} data-dismiss="modal" onClick={() => this.wipe()}></span>
            </div>
            <div className="modal-body">
              {fields.map(f => {
                const value = this.state[f.name]
                // console.log(`Error: ${f.name}`, error && error[f.name])
                return (
                  <div className="form-group" key={f.name + "field"}>
                    <label htmlFor={f.name}>{f.title}</label>
                    {f.type === 'textarea' ? (
                      <textarea className="form-control" rows="2" name={f.name} placeholder={f.placeholder || ""} onChange={this._handleTextChange} value={value}></textarea>
                    ) : (
                        f.type === 'comment' ? (
                          <div className="form-text">
                            {f.text}
                          </div>
                        ) : (
                            <input type={f.type || "text"} name={f.name} className="form-control" id={f.name} placeholder={f.placeholder || ""} value={value} onChange={this._handleTextChange} />
                          )
                      )
                    }
                    {error && error[f.name] && (<div className="fs-10 font-italic thin" style={{ color: "red" }}>{error[f.name]}</div>)}
                  </div>
                )
              })}
              {error && error.message && (<div className="fs-12 thin" style={{ color: "red" }}>Eroare de comunicare.<br />Va rugam reincercati sau contactati-ne daca problema persista.</div>)}
            </div>
            {successMessage ? (
              <div className="modal-footer">
                <div className="alert alert-success w-100 py-1" role="alert">
                  {successMessage}
                </div>
              </div>
            ) : (
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Inchide</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>Trimite {submitting && (<i className="fa fa-refresh fa-spin fa-fw"></i>)}</button>
                </div>
              )}

          </div>
        </div>
      </div>
    )
  }
}
