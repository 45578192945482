import { useEffect, useState } from 'react';

const mobileRex = /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i

export const isTouchScreenDevice = () => {
    try {
        if ("maxTouchPoints" in navigator && navigator.maxTouchPoints > 0) return true;
        if (window.matchMedia?.("(any-pointer:coarse)")?.matches) {
            return true;
        }
        else {
            return mobileRex.test(navigator.userAgent)
        }
    } catch (err) {
        return false;
    }
}

export const isMobile = () => mobileRex.test(navigator.userAgent);

// const isMobileFuture = () => { // not supported by mobile browsers yet
//     try {
//         if ("userAgentData" in navigator) {
//             const data = navigator.userAgentData as any;
//             return !!data.mobile;
//         }
//         return false;
//     } catch (err) {
//         return false;
//     }
// }


export const useMobile = () => {
    const [orient, setOrient] = useState<string | null>(null);

    if (window.screen.orientation) {
        window.screen.orientation.addEventListener('change', (ev) => {
            const newValue = (ev.target as ScreenOrientation).type
            if (newValue) {
                setOrient(newValue)
            }
        })
    } else {
        console.error('Browser doesn\'t support window.screen.orientation');
    }

    useEffect(() => {
        if (window.screen.orientation?.type) {
            setOrient(window.screen.orientation.type)
        }
    }, [])

    return {
        orientation: orient,
        touchEnabled: isTouchScreenDevice(),
        mobile: isMobile(),
    }
}
