import React from 'react'

const UpdateNotification = ({ updateComplete, onClose, onRestart }) => {
  return (
    <div className="card" style={{
      position: 'fixed',
      bottom: 20,
      right: 20,
      zIndex: 99999,
    }}>
      <div className="card-header bg-dark text-light d-flex justify-content-between align-items-start">
        {/* <h5 className="card-title">Versiune noua</h5> */}
        Versiune noua
        <button type="button" className="close" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true" className="text-light">&times;</span>
        </button>
      </div>
      <div className="card-body bg-warning">
        {!updateComplete
          ? (
            <>
              <p>O noua versiune este disponibila.</p>
              <p>Se descarca...</p>
            </>
          )
          : (
            <>
              <p>Versiunea noua s-a descarcat.</p>
              <p>Se va activa la restartarea aplicatiei.</p>
              <p>Doriti sa restartati Lex acum?</p>
            </>
          )
        }
        {/* <button className="btn" onClick="closeNotification()">
          Close
        </button> */}
        {updateComplete && <button className="btn btn-sm btn-dark" onClick={onRestart}>
          Restart Lex
        </button>}
      </div>
    </div>
  )
}

export default UpdateNotification
