
import { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import '../styles/banner.scss'

const Banner = ({ type, text, details, onDismiss, cannotClear = false, alignCenter = false, fullWidth = false, style, className }: { type: string, text: any, details?: any, onDismiss?: () => void, cannotClear?: boolean, alignCenter?: boolean, fullWidth?: boolean, style?: any, className?: string }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    if (details) {
      setShowDetails(prev => !prev)
    }
  }

  return (

    <div className={`lex-banner ${type} ${className || ''}`} style={style}>
      <div className='lex-banner-header'>
        <div className='lex-banner-header-text' onClick={toggleShowDetails} style={{ cursor: details ? 'pointer' : undefined, textAlign: alignCenter ? 'center' : undefined, width: alignCenter ? '100%' : undefined }}>{text}</div>
        {
          !cannotClear
            ? <Icons.X onClick={() => onDismiss?.()} className='lex-banner-close-icon' />
            : null
        }
      </div>
      {
        details && showDetails
          ? <div className='lex-banner-details'>{details}</div>
          : null
      }
    </div>
  )
}

export default Banner;
