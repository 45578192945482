import React, { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from './context'

import { isMobile } from './customhooks/useMobile';
import LatestDocsWidget from './fragments/LatestDocsWidget'
import RecentDocsWidget from './fragments/RecentDocsWidget'
import UpdateStatus from './UpdateStatus';
import { Tabs, Tab } from 'react-bootstrap';

const Home = () => {
  const { unseenCount, api } = useContext(UserContext)

  const [latestDocsGroupedByDate, setLatestDocsGroupedByDate] = useState({ loading: false, results: [] })
  const [recentDocs, setRecentDocs] = useState({ loading: false, results: [] })

  const mobile = isMobile();

  const fetchRecents = useCallback(async () => {
    setRecentDocs({ loading: true, results: [] })
    const recents = await api.recent(30)
    setRecentDocs({ loading: false, results: recents })
  }, [api])

  const fetchLatest = useCallback(async () => {
    setLatestDocsGroupedByDate({ loading: true, results: [] })
    const latests = await api.latest(20, 10)
    setLatestDocsGroupedByDate({ loading: false, results: latests })
  }, [api])


  useEffect(() => {
    fetchLatest()
    fetchRecents()
  }, [fetchLatest, fetchRecents])

  return (
    <div
      className="home d-flex"
      style={{
        overflowY: 'scroll',
        height: unseenCount
          ? 'calc(100% - 5rem)'
          : '100%',
        flexDirection: mobile
          ? 'column'
          : 'row',
        gap: mobile ? 0 : '1rem',
        padding: '0 1rem',
        paddingBottom: '2rem',
      }}>
      <UpdateStatus />
      {
        mobile
          ? <Tabs defaultActiveKey='latest'>
            <Tab eventKey='latest' title='Noutati legislative'>
              <LatestDocsWidget icon="bullhorn" title="Noutati legislative" items={latestDocsGroupedByDate.results} onRefresh={fetchLatest} loading={latestDocsGroupedByDate.loading} mobile />
            </Tab>
            <Tab eventKey='recent' title='Recent accesate'>
              <RecentDocsWidget icon="history" title="Recent accesate" items={recentDocs.results} onRefresh={fetchRecents} loading={recentDocs.loading} mobile />
            </Tab>
          </Tabs>
          : <>
            <LatestDocsWidget icon="bullhorn" title="Noutati legislative" items={latestDocsGroupedByDate.results} onRefresh={fetchLatest} loading={latestDocsGroupedByDate.loading} />
            <RecentDocsWidget icon="history" title="Recent accesate" items={recentDocs.results} onRefresh={fetchRecents} loading={recentDocs.loading} />
          </>
      }

      {/* <div className="container pb-2" >
        <div className="row">
          <div className="col-6">
          </div>
          <div className="col-6">
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Home
