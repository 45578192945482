import React, { useCallback, useContext, useState } from 'react';
import { searchParams2searchTextNew } from '../lib/searchutils';
import cache from '../lib/cache'
import { UserContext } from '../context';
import { ErrorsContext } from './ErrorsContext';

export type ComplexSearchItem = {
  type?: string,
  value: string,
}

export type SearchParms = {
  [name: string]: boolean | string | ComplexSearchItem[],
}

export const SearchContext = React.createContext({
  searchText: '',
  updateSearchParam: (name: string, value: boolean | string | ComplexSearchItem[]) => { },
  overrideSearchParams: (newParams: SearchParms) => { },
  performSearch: (start?: number) => { },
  searchParams: {} as SearchParms,
  isCaseSearch: false,
  setIsCaseSearch: (value: boolean) => { },
  clearResults: () => { },
  searching: false,
  searchMeta: { total: 0, took: 0 },
  results: [],
  toggleTracked: async (result: any) => { },
})

export const SearchContextWrapper = ({ children }: { children: any }) => {

  const { api, searchOptions, caseSearchOptions } = useContext(UserContext)
  const { addMessage } = useContext(ErrorsContext)


  const [searchText, setSearchText] = useState('')
  const [searchParams, setSearchParams] = useState<SearchParms>({})
  const [isCaseSearch, setIsCaseSearch] = useState(false);

  const [results, setResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [searchMeta, setSearchMeta] = useState({ total: 0, took: 0 })

  const updateSearchParam = (name: string, value: boolean | string | ComplexSearchItem[]) => {
    setSearchParams(prev => ({ ...prev, [name]: value }))
    setSearchText(searchParams2searchTextNew(searchParams, isCaseSearch))
  }


  const overrideSearchParams = (newParams: SearchParms) => {
    console.log('searching');
    console.log(newParams);
    setSearchParams(newParams);
    setSearchText(searchParams2searchTextNew(newParams, newParams.isCaseSearch))
  }

  const clearResults = () => {
    setResults([])
  }

  const performSearch = useCallback(async (start = 0) => {
    if (!Object.keys(searchParams).length || !api) {
      document.title = 'Lex - Program Legislativ'
      return
    }

    setSearching(true)

    const cleanSearchParams: Record<string, any> = {}
    Object.keys(searchParams).forEach(key => {
      cleanSearchParams[key] = searchParams[key]
        ? Array.isArray(searchParams[key])
          ? (searchParams[key] as ComplexSearchItem[]).length
            ? searchParams[key]
            : undefined
          : searchParams[key]
        : undefined
    })
    const relevantSearchParams: SearchParms = {}

    if (isCaseSearch) {
      setResults([])

      caseSearchOptions.forEach((option: any) => {
        relevantSearchParams[option.name] = searchParams[option.name]
      })
    } else {
      searchOptions.forEach((option: any) => {
        relevantSearchParams[option.name] = searchParams[option.name]
      })
    }

    const description = 'Lex - Căutare'
    const cachedSearchResults = cache.get(JSON.stringify(relevantSearchParams))

    document.title = description

    if (start === 0) {
      //search in cache only if start == 0 (first time or reload)
      if (cachedSearchResults) {
        // console.log('loaded search results from cache')
        const { results: cachedResults, total: cachedTotal } = cachedSearchResults
        setResults(cachedResults)
        setSearchMeta({ total: cachedTotal, took: 0 })
        setSearching(false)
        return
      }
    }

    try {

      const response = await (api as any).search(
        {
          ...relevantSearchParams,
          isCaseSearch,
          institutions: searchParams.institutions && (searchParams.institutions as ComplexSearchItem[]).length
            ? (searchParams.institutions as ComplexSearchItem[]).map(i => i.value)
            : undefined
        },
        start,
        20,
        start === 0
      )
      // console.info('RESPONSE', response)
      const formattedResults = isCaseSearch
        ? response.results
        : response.results.map(({ highlight, _id, _score, _source }: any) => {
          // console.info('Processing', _id, _source, _score, highlight)
          return {
            ..._source,
            score: _score,
            id: _id,
            highlight
          }
        })
      // console.info('RESULTS', formattedResults)
      const { total, took } = response
      const aggs = isCaseSearch
        ? undefined
        : start === 0
          ? response.aggs.aggs
          : null
      setSearchMeta({ total, took })
      // setSearchAggs(aggs)

      setResults(prev => {
        const newResults = start > 0 ? [...prev, ...formattedResults] : formattedResults
        cache.set(JSON.stringify(relevantSearchParams), { results: newResults, aggs, total, took })
        return newResults
      })
    } catch (error) {
      console.error(error)
      console.error((error as Error).message)
      addMessage({ type: 'error', text: 'A apărut o eroare la căutare', errorMessage: (error as Error).message })
    }

    setSearching(false)
  }, [searchParams, api, isCaseSearch, caseSearchOptions, searchOptions, addMessage])

  const toggleTracked = useCallback(async (result: any) => {
    if (api) {
      console.info('toggleTrack', result)
      result.tracked
        ? await (api as any).stopTrackingDocument(result.revdata.chain_id)
        : await (api as any).startTrackingDocument(result.revdata.chain_id)
      result.tracked = !!!result.tracked
      setResults(prev => [...prev])
    }
  }, [api])


  return (
    <SearchContext.Provider value={{
      searching,
      searchText,
      searchParams,
      isCaseSearch, setIsCaseSearch,
      clearResults,
      searchMeta,
      results,
      updateSearchParam,
      overrideSearchParams,
      performSearch,
      toggleTracked
    }}>
      {children}
    </SearchContext.Provider>
  )
}
