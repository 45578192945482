import React from 'react'
import { date2DDMMMMMYYYString } from '../lib/textutils'

const DocDescription = (props) => {
  if (!props.doc) return null
  const {
    // html,
    // reactified,
    newtype: type,
    publish_nr, publish_year, publisher_name, publish_date,
    status_name,
    status_code,
    issue_year,
    issuers,
    domains,
    // revdata
  } = props.doc
    // , actionString = revdata.rev_type === 'Publicare' ? revdata.rev_number === 1 ? 'Publicare' : 'Republicare' : 'Modificare'
    // , formattedRevDate = date2DDMMMMMYYYString(new Date(revdata.rev_date))
    , formattedPubDate = date2DDMMMMMYYYString(new Date(publish_date))
    // , issuerTexts = issuers ? issuers.map(i => `${i.issuer_name} Nr. ${i.issuer_nr}/${issue_year}`) : ""
    , nran = issuers ? `${issuers[0].issuer_nr}/${issue_year}` : ""
  // , singleIssuerNumber = issuers.length === 1 ? ` Nr. ${issuers[0].issuer_nr}/${issue_year}` : ""
  // , titleShort = title.length > 80 ? title.substr(0, 80) + '...' : title
  let statusBadgeClass = 'light'
  switch (status_code) {
    case 'A':
    case 'I':
      statusBadgeClass = 'danger'
      break;
    case 'B':
    case 'L':
    case 'O':
    case 'v':
      statusBadgeClass = 'warning'
      break;
    case 'C':
    case 'M':
      statusBadgeClass = 'info'
      break;
    case 'V':
    case 'P':
    case 'R':
    case 'r':
      statusBadgeClass = 'success'
      break;
    default:
      statusBadgeClass = 'secondary'
  }
  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="publish-text">{publisher_name.toUpperCase()} nr. {publish_nr}/{publish_year} din {formattedPubDate}</div>
        <div className="type-nran-text ml-2"><strong>{type}</strong> nr. {nran}</div>
      </div>
      <div className="d-flex flex-row flex-wrap" style={{gap: '0.5em'}}>
        <div className='d-flex flex-row align-items-center' style={{width: 'max-content'}}>
          <span className="text-muted small">emitenti: </span>
          <span className="emitenti-text">{`${issuers.map(i => i.issuer_name.toUpperCase()).join(',')}`}</span>
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: 'max-content'}}>
          <span className="text-muted small">tematici: </span>
          <span className="emitenti-text">{`${domains.map(i => i.domain_name.toUpperCase()).join(',')}`}</span>
        </div>
        <div className='d-flex flex-row align-items-center' style={{width: 'max-content'}}>
          <span className="text-muted small">stare: </span>
          <span className={`badge ml-1 badge-pill badge-${statusBadgeClass}`}>{status_name}</span>
        </div>
      </div>
    </>
  )
}
export default DocDescription
