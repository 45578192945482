import React, { useContext, useEffect, useState } from 'react'
import './styles/App.css'
import './styles/mobile.scss'

import isElectron from 'is-electron';

import $ from 'jquery'
import 'popper.js'

import { Route } from 'react-router'

import { BrowserRouter, Redirect, HashRouter } from 'react-router-dom'
import SearchNew from './SearchNew'
import MyAccount from './MyAccount'
import Login from './LoginForm'
import Logout from './Logout'

import PrivateRoute from './components/PrivateRoute';

import SearchHelpModal from './fragments/SearchHelpModal'
import ContactModal from './fragments/ContactModal'
import RegisterModal from './fragments/RegisterModal'
import YearMonIndexPanel from './YearMonIndexSidePanel'
import { LoginContextWrapper } from './context'
import { SearchContextWrapper } from './contexts/SearchContext'
import { ErrorsContextWrapper } from './contexts/ErrorsContext'
import UpdateNotification from './UpdateNotification'
import BetterViewer from './BetterViewer'
import CaseViewer from './CaseViewer';
import { isMobile } from './customhooks/useMobile';
import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DesktopMenu';
import MobileAdvancedSearch from './pages/MobileAdvancedSearch';
import MonitoredPage from './pages/MonitoredPage';
import Index from './pages/Index';

const appRuntime = window.appRuntime

window.$ = $

console.log('Electron', isElectron())
const Router = window.location.toString().startsWith('http') ? BrowserRouter : HashRouter

const App = () => {

  const [updating, setUpdating] = useState(false)
  const [updateComplete, setUpdateComplete] = useState(false)

  const mobile = isMobile();

  useEffect(() => {
    if (appRuntime) {
      appRuntime.subscribe('update_available', () => {
        setUpdating(true)
      });
      appRuntime.subscribe('update_downloaded', () => {
        setUpdateComplete(true)
      })
    }
  }, [])

  return (
    <Router>
      <LoginContextWrapper>
        <ErrorsContextWrapper>
          <div className={`lex-app ${mobile ? 'lex-mobile' : ''}`}>
            {
              updating
                ? <UpdateNotification
                  updateComplete={updateComplete}
                  onRestart={() => appRuntime.send('restart_app', null)}
                  onClose={() => setUpdating(false)}
                />
                : null
            }
            <Route exact path="/login" component={Login} />

            <SearchContextWrapper>
              {
                mobile
                  ? <MobileMenu />
                  : <DesktopMenu />
              }
              <PrivateRoute exact path="/" component={SearchNew} />
              <PrivateRoute exact path="/search" component={SearchNew} />
              {
                mobile
                  ? <>
                    <PrivateRoute exact path="/advanced-search" component={MobileAdvancedSearch} />
                    <PrivateRoute exact path="/monitored" component={MonitoredPage} />
                    <PrivateRoute exact path="/index" component={Index} />
                  </>
                  : null
              }
              <PrivateRoute path="/view/:docid/:docSearchText?" component={BetterViewer} />
              <PrivateRoute path="/viewCase/:docid/:institution/:docSearchText?" component={CaseViewer} />
              <PrivateRoute path="/viewlexlink/:lexid/:docSearchText?" component={BetterViewer} />
            </SearchContextWrapper>

            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/account" component={MyAccount} />
            <PrivateRoute exact path="/index-mon" component={YearMonIndexPanel} />

            <SearchHelpModal />
            <ContactModal />
            <RegisterModal />
          </div>
        </ErrorsContextWrapper>
      </LoginContextWrapper>
    </Router>
  )
}

export default App;
