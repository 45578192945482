import React, { Component } from 'react'
import autobind from 'react-autobind'
import { isMobile } from '../customhooks/useMobile'
import TooltipWrapper from './TooltipWrapper'

const rightBorderlessStyle = {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  borderRightWidth: 0
},
  leftBorderlessStyle = {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderLeftWidth: 0
  }
const ESCAPE_KEY = 27
export default class SidePanel extends Component {

  constructor(props) {
    super(props)
    this.state = { hidden: true }
    autobind(this)
  }


  componentDidMount() {
    document.addEventListener("keydown", this._handleKeydown)
    document.addEventListener("mousedown", this._handleMousedown)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeydown)
    document.removeEventListener("mousedown", this._handleMousedown)
  }

  _handleKeydown(event) {
    if (this.state.hidden)
      return
    switch (event.keyCode) {
      case ESCAPE_KEY:
        this.setState({ hidden: true })
        break;
      default:
        break;
    }
  }

  _handleMousedown(event) {
    if (this.state.hidden)
      return
    const myTitleRect = document.getElementById('title-' + this.props.id).getBoundingClientRect()
    const myBodyRect = document.getElementById('body-' + this.props.id).getBoundingClientRect()
    // console.log({myTitleRect,myBodyRect})
    // console.log(event)
    const outsideOfTitle = event.x < myTitleRect.left || event.x > myTitleRect.right || event.y < myTitleRect.top || event.y > myTitleRect.bottom
      , outsideOfBody = event.x < myBodyRect.left || event.x > myBodyRect.right || event.y < myBodyRect.top || event.y > myBodyRect.bottom
    if (outsideOfBody && outsideOfTitle) {
      this.setState({ hidden: true })
    }
  }

  onToggle() {
    this.setState({ hidden: !this.state.hidden })
  }
  render() {
    const { title, top, width, children, btnclass, lefty, topTitleComponent, id, tooltip } = this.props;
    const { hidden } = this.state;
    const mobile = isMobile();
    const headerHeight = 30;
    return (
      <div style={{ position: 'absolute', top: 0, width: 0, right: 54, height: '100%', zIndex: 99 }} className={lefty ? 'side-panel-wrap lefty' : 'side-panel-wrap righty'}>
        <TooltipWrapper
          className="pr-2 lex-tooltip-pl lex-tooltip-lighter"
          desc={hidden ? tooltip : undefined}
          placement="left"
        >
          <div onClick={() => this.onToggle()} className={`panel-button btn ${btnclass || ''}`} style={{ zIndex: 9999, position: 'absolute', top: top, cursor: 'pointer', ...(lefty ? leftBorderlessStyle : rightBorderlessStyle) }}>{title}</div>
        </TooltipWrapper>
        {
          !hidden
            ?
            <div className="side-panel-body-wrap" style={{ position: 'absolute', top: top, right: 0, height: `calc(100% - ${top}px`, minWidth: mobile ? `calc(min(${width}px, 100vw) - 70px)` : width, maxWidth: 'calc(100% - 70px)' }}>
              <div id={`title-${id}`} className={`side-panel d-flex justify-content-between align-items-center`} style={{ position: "relative", height: headerHeight, top: mobile ? 0 : -headerHeight }}>
                {topTitleComponent}
                <div className="text-center" onClick={this.onToggle} style={{ width: 24, height: 24, cursor: 'pointer' }}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div id={`body-${id}`} className='side-panel panel-body' style={{ position: "relative", top: mobile ? 0 : -headerHeight, maxHeight: '100%', overflowY: 'scroll', }}>
                <div className="panel-content" style={lefty ? leftBorderlessStyle : rightBorderlessStyle}>
                  {children}
                </div>
              </div>
            </div>
            : null
        }
      </div>
    )
  }

}
