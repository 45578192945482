import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "./context";
import isElectron from "is-electron";
import * as WS from "./lib/ws";
import * as dayjs from 'dayjs';

const UpdateStatus = () => {
  const { setLoggedInUser, api } = useContext(UserContext);

  const [msg, setMsg] = useState(null);

  const handleMessage = useCallback(
    (message) => {
      console.info("Incoming from server", message);
      const { logout = false, diskUsage } = message;
      if (diskUsage) {
        // code to be added here by Adi
      } else if (logout) {
        api.logout().then((ok) => {
          setLoggedInUser(null);
        });
      } else {
        setMsg(message);
      }
    },
    [setLoggedInUser, api]
  );

  useEffect(() => {
    WS.connect(handleMessage);
    return () => WS.disconnect();
  }, [handleMessage]);

  if (!msg) return null;
  const { docsInLocalDb, docsTotal, dbUpdateStopped, mostRecentDoc, stopReason = "" } = msg;
  const perc = Math.round((100 * docsInLocalDb) / docsTotal);
  return (
    <div style={{ position: "fixed", bottom: 0, right: 60, zIndex: 100 }}>
      {
        dbUpdateStopped
          ? <div className="badge rounded-pill bg-warning text-dark">Atentie! Actualizarea documentelor oprita.<br />Motiv: {stopReason} </div>
          : <div className="progress p-relative" style={{ width: 300, height: 32, borderRadius: 2 }}>
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              aria-valuenow={perc}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${perc}%` }}
            ></div>
            <div
              style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 12 }}
              className="d-flex justify-content-center align-items-center"
            >
              Documente: {docsInLocalDb} / {docsTotal}
            </div>
            <div
              style={{ position: "absolute", top: 12, left: 0, right: 0, bottom: 0 }}
              className="d-flex justify-content-center align-items-center"
            >
              Data actualizare: {mostRecentDoc}
            </div>
          </div>
      }
    </div>
  );
};

const UpdateStatusWrapped = () => {
  return isElectron() && document.location.href.startsWith('file') ? <UpdateStatus /> : null;
};

export default UpdateStatusWrapped;
