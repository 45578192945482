import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { modalId as registerModalId } from './fragments/RegisterModal'
import logo from './img/logo-white-h200.png'
import { UserContext } from './context'
import Spinner from './fragments/Spinner'

const Login = props => {

  const location = useLocation()
  const { loggedInUser, login } = useContext(UserContext)

  // const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  // const [checked, setChecked] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (document.getElementById('em')) {
      document.getElementById('em').textContent = "info" + String.fromCharCode(64) + "program-legislatie.ro"
      document.getElementById('em').setAttribute("href", "mailto:info" + String.fromCharCode(64) + "program-legislatie.ro")
    }
  }, [])


  const doLogin = async () => {
    try {
      setLoading(true)
      await login(email, pass)
    } catch (err) {
      setError(err)
      setLoading(false)
      // console.error(err)
    }
  }

  if (loggedInUser) {
    const redirect = location.search ? location.search.substr(1).split('&').find(v => v.startsWith('r='))?.substr(2) : undefined
    const to = redirect ? decodeURIComponent(redirect) : '/'
    return <Redirect to={to} />
  }
  return (
    <div id="login-screen" className="h-100 account-screen">
      <div className="container h-100">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div>
            <div className="d-flex flex-row justify-content-center align-items-center white mb-4 pb-2">
              <img src={logo} height="100" width="auto" style={{ height: 100, width: "auto" }} alt="logo" />
            </div>
            <div className="card card-body mt-4" style={{ minWidth: 300, maxWidth: 350 }}>
              <div className="form">
                <div className="row">
                  <div className="col-12">
                    <div className="title">Autentificare cont</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group has-danger">
                      <label className="sr-only">E-Mail Address</label>
                      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                        {/* <div className="input-group-addon" style={{ width: "2.6rem" }}><i className="fa fa-at"></i></div> */}
                        <input type="email" name="u" className="form-control form-control-sm" id="sapemail" placeholder="Email"
                          value={email} onChange={e => setEmail(e.target.value)} autoFocus />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="sr-only">Password</label>
                      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                        {/* <div className="input-group-addon" style={{ width: "2.6rem" }}><i className="fa fa-key"></i></div> */}
                        <input type="password" name="p" className="form-control form-control-sm" id="password"
                          value={pass} placeholder="Parola" onChange={e => setPass(e.target.value)}
                          onKeyUp={e => {
                            if (e.code === 'Enter')
                              doLogin()
                          }} required />
                      </div>
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="row">
                    <div className="col-12 pt-2 error">
                      <div className="alert alert-danger alert-dismissible login-error" role="alert">
                        {/\n/.test(error.message) ? (error.message.split('\n').map((l, i) => <div key={`error-${i}`}>{l}</div>)) : error.message}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row pt-3">
                  <div className="col-12">
                    <button className="btn btn-primary fs-14 btn-block py-3 thin" onClick={() => doLogin()}>
                      {/* <i className="fa fa-sign-in"></i>  */}
                      INTRA IN CONT</button>
                    {/* <a className="btn btn-link" href="{{baseUrl}}/admin/password-reset">Forgot Your Password?</a> */}
                  </div>
                  {/* <div className="btn btn-link pull-right thin" onClick={saveProfileData}>{savingProfileData ? <Spinner /> : null} Salveaza</div> */}
                  <div className="col-12" >
                    <Spinner className="d-flex justify-content-center align-items-center pb-2" spin={loading} />
                  </div>
                </div>
                {/* <div className="row pt-3">
                    <div className="col-12 text-center">
                      <div className="text-muted thin d-inline-block fs-13 cursor-hand" onClick={this.forgotPassword}>Am uitat parola</div>
                    </div>
                  </div> */}
              </div>

            </div>
            <div className="white thin fs-13 text-center my-1 d-flex justify-content-around">
              <div>Nu ai cont?</div>
              <div>|</div>
              <div className="cursor-hand" data-toggle="modal" data-target={`#${registerModalId}`}> Inregistreaza-te</div>
            </div>
          </div>
          <div className="white thin fs-13 text-center w-100" style={{ position: "absolute", bottom: 5, left: 0 }}>
            <div>
              Linia de asistenta de luni pana vineri intre orele  8:00-18:00:
            </div>
            <div>
              0256-487402 | 0788-123492 | 0788-123496
            </div>
            <div>
              Departament  marketing - vanzari:
            </div>
            <div>
              0722-264869 | 0788-123496
            </div>
            <div>
              email: <a id="em" href="#email" className="white"> </a>
            </div>
          </div>
        </div>
      </div>
      {/* <RegisterModal onRegister={this.register} registering={this.state.registering} error={this.state.registrationError} /> */}
    </div>

  )
}
export default Login
