import { useState, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { UserContext } from '../context';
import YearTypeIndexPanel from '../YearTypeIndexSidePanel';
import YearMonIndexPanel from '../YearMonIndexSidePanel';
import { isMobile } from '../customhooks/useMobile';

const Index = () => {
  const mobile = isMobile();
  const [activeKey, setActiveKey] = useState('year');

  const { unseenCount, } = useContext(UserContext)
  return (
    <div
      className="home d-flex"
      style={{
        overflowY: 'scroll',
        height: unseenCount
          ? 'calc(100% - 5rem)'
          : '100%',
        flexDirection: mobile
          ? 'column'
          : 'row',
        gap: mobile ? 0 : '1rem',
        padding: '1rem',
        paddingBottom: '2rem',
        overflow: mobile ? 'hidden' : undefined,
      }}>
      {/* TODO - render data for desktop */}
      {
        mobile
          ? <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onSelect={(key) => { if (key) setActiveKey(key) }} >
            <Tab eventKey='year' title='Index pe ani' >
              <YearTypeIndexPanel />
            </Tab>
            <Tab eventKey='monitor' title='Index monitoare'>
              <YearMonIndexPanel />
            </Tab>
          </Tabs>
          : null
      }
    </div>
  )
}

export default Index;
