import { useHistory } from "react-router";
import AdvancedSearchBoxNew from "../fragments/AdvancedSearchBoxNew";

const MobileAdvancedSearch = () => {
  const history = useHistory();
  return (
    <AdvancedSearchBoxNew
      onHide={() => { }}
      onSearch={() => history.push('/search')}
    />
  )
}

export default MobileAdvancedSearch;
