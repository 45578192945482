import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './styles/index.scss';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import register, { unregister } from './registerServiceWorker'
register()

ReactDOM.render(<App />, document.getElementById('root'))
// registerServiceWorker()
