import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/tooltip.scss'

const TooltipWrapper = ({ className, children, id, desc, placement, trigger, showDelayMs = 250, hideDelayMs = 100 }: {
  className?: string,
  children: any,
  id?: string,
  desc?: any,
  placement?: 'top' | 'right' | 'bottom' | 'left',
  trigger?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[],
  showDelayMs?: number,
  hideDelayMs?: number,
}) => {

  if (!desc) return <>{children}</>

  return (
    <OverlayTrigger
      trigger={trigger || ['hover', 'focus']}
      placement={placement || 'bottom'}
      delay={{ show: showDelayMs, hide: hideDelayMs }}
      overlay={
        <Tooltip id={id || 'button-tooltip'} className={`lex-tooltip ${className}`}>
          {desc}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default TooltipWrapper
