import React, { useContext, useState } from 'react'
import { UserContext } from '../context'
import FormModal from './FormModal'

export const modalId = 'contact-form'

const ContactModal = () => {

  const { api } = useContext(UserContext)
  const [state, setState] = useState({ submitting: true, submitError: null, successMessage: null })

  const onSubmit = async data => {
    setState({ submitting: true, submitError: null, successMessage: null })
    try {
      await api.register(data)
      setState({ submitting: false, successMessage: 'Cererea dvs a fost trimisa cu success. Va vom contacta in cel mai scurt timp posibil!' })
      setTimeout(() => {
        document.getElementById('wipe-' + modalId).click()
      }, 2500)
    } catch (error) {
      setState({ submitting: false, submitError: error, successMessage: null })
    }
  }

  return (

    <FormModal
      modalId={modalId}
      onSubmit={onSubmit}
      submitting={state.submitting}
      error={state.submitError}
      successMessage={state.successMessage}
      title="Contacteaza-ne"
      fields={
        [
          { name: "comment", type: "comment", title: "", text: "Prin acest formular ne puteti trimite sugestii, semnala probleme sau ne puteti cere imbunatatiri." },
          { name: "msg", type: "textarea", title: "Mesaj", placeholder: "Lasati-ne un mesaj" },
        ]
      }
    />

  )
}

export default ContactModal
