import React, { useCallback, useState } from 'react';
import { isMobile } from '../customhooks/useMobile';
import Banner from '../fragments/Banner';
import '../styles/errors.scss'


type MessageType = {
  type: string,
  text: string,
  errorMessage?: string,
  cannotClear?: boolean,
}

export const ErrorsContext = React.createContext({
  errors: [] as MessageType[],
  addMessage: (m: MessageType) => { },
  removeMessage: (m: MessageType) => { },
})

export const ErrorsContextWrapper = ({ children }: { children: any }) => {
  const [errors, setErrors] = useState<MessageType[]>([])

  const mobile = isMobile();

  const addMessage = useCallback((m: MessageType) => {
    if (!m.cannotClear) {
      setErrors(prev => ([
        ...prev.filter(e => e.text !== m.text),
        m
      ]))
    }
  }, [])

  const removeMessage = useCallback((m: MessageType) => {
    setErrors(prev => prev.filter(e => e.type !== m.type && e.text !== m.text && (m.errorMessage ? e.errorMessage !== m.errorMessage : true)))
  }, [])

  return (
    <ErrorsContext.Provider value={{
      errors,
      addMessage,
      removeMessage,
    }}>
      {
        errors.length
          ? <div className={`lex-errors ${mobile ? 'lex-mobile' : ''}`}>
            {
              errors.map((e, idx) =>
                <Banner
                  key={idx}
                  type={e.type}
                  text={e.text}
                  details={e.errorMessage}
                  onDismiss={() => removeMessage(e)}
                />)
            }
          </div>
          : null
      }
      {children}
    </ErrorsContext.Provider>
  )
}
