import React, { useContext, useState } from 'react'
import { UserContext } from '../context'
import FormModal from './FormModal'

export const modalId = 'register-form'

const RegisterModal = () => {

  const { api } = useContext(UserContext)
  const [state, setState] = useState({ submitting: false, submitError: null, successMessage: null })

  const onSubmit = async data => {
    setState({ submitting: true, submitError: null, successMessage: null })
    try {
      await api.register(data)
      setState({ submitting: false, successMessage: 'Cererea dvs a fost trimisa cu success. Va vom contacta in cel mai scurt timp posibil!' })
      setTimeout(() => {
        document.getElementById('wipe-' + modalId).click()
      }, 2500)
    } catch (error) {
      setState({ submitting: false, submitError: error, successMessage: null })
    }
  }

  return (

    <FormModal
      modalId={modalId}
      onSubmit={onSubmit}
      submitting={state.submitting}
      error={state.submitError}
      successMessage={state.successMessage}
      title="Inregistrare"
      fields={
        [
          { name: "nume", title: "Nume", placeholder: "Numele dvs" },
          { name: "tel", title: "Telefon", placeholder: "Numarul dvs de telefon" },
          { name: "email", type: "email", title: "Email", placeholder: "Adresa dvs de email" },
          { name: "loc", title: "Localitate", placeholder: "Localitatea dvs" },
          // { name: "src", title: "Cum ne-ati gasit", options: [""] },
          { name: "msg", type: "textarea", title: "Mesaj (optional)", placeholder: "Ne puteti lasa si un scurt mesaj" },
        ]
      }
      initialValues={{
        nume: '',
        email: '',
        tel: '',
        loc: '',
        msg: '',
        // nume: "Ion",
        // email: "rpocaznoi@gmail.com",
        // tel: "0744123123",
        // loc: "Timisoara",
        // msg: "test"
      }
      }
    />
  )
}

export default RegisterModal
