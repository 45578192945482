import { useContext, useState, useEffect, useCallback } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { UserContext } from '../context';
import { isMobile } from '../customhooks/useMobile';
import RecentDocsWidget from '../fragments/RecentDocsWidget';
import TrackedDocsSidePanel from '../TrackedDocsSidePanel';

const MonitoredPage = () => {
  const mobile = isMobile();
  const [activeKey, setActiveKey] = useState('monitored');

  const { unseenCount, api, refreshTrackedDocsCountAndStatus } = useContext(UserContext)

  const [recentDocs, setRecentDocs] = useState({ loading: false, results: [] })

  const fetchRecents = useCallback(async () => {
    if (api) {
      setRecentDocs({ loading: true, results: [] })
      const recents = await (api as any).recent(30)
      setRecentDocs({ loading: false, results: recents })
    }
  }, [api])


  useEffect(() => {
    fetchRecents()
  }, [fetchRecents])

  return (
    <div
      className="home d-flex"
      style={{
        overflowY: 'scroll',
        height: unseenCount
          ? 'calc(100% - 5rem)'
          : '100%',
        flexDirection: mobile
          ? 'column'
          : 'row',
        gap: mobile ? 0 : '1rem',
        padding: '1rem',
        paddingBottom: '2rem',
        overflow: mobile ? 'hidden' : undefined,
      }}>
      {/* TODO - render data for desktop */}
      {
        mobile
          ? <Tabs defaultActiveKey={activeKey} activeKey={activeKey} onSelect={(key) => { if (key) setActiveKey(key) }} >
            <Tab eventKey='monitored' title='Acte monitorizate' >
              {/* <LatestDocsWidget icon="bullhorn" title="Noutati legislative" items={latestDocsGroupedByDate.results} onRefresh={fetchLatest} loading={latestDocsGroupedByDate.loading} mobile /> */}
              <TrackedDocsSidePanel refresh={refreshTrackedDocsCountAndStatus} />
            </Tab>
            <Tab eventKey='recent' title='Recent accesate'>
              <RecentDocsWidget icon="history" title="Recent accesate" items={recentDocs.results} onRefresh={fetchRecents} loading={recentDocs.loading} mobile />
            </Tab>
          </Tabs>
          : null
      }
    </div>
  )
}

export default MonitoredPage
