import _ from 'lodash'

const ORDERED_TYPES = [
  "LEGE",
  "HOTARARE GUVERN",
  "ORDIN ADMINISTRATIE PUBLICA",
  "ORDONANTA DE URGENTA",
  "COD",
  "ORDONANTA",
  "HOTARARE",
  "ORDIN",
  "DECRET",
  "DECRET-LEGE",
  "DISPOZITIE",
  "MODEL DE CONTRACT",
  "ANEXA",
  "REGULAMENT",
  "DECIZIE",
  "PROCEDURA",
  "TABEL",
  "LISTA",
  "SPETE",
  "CONTRACT"
]

const TYPES2SHORT_TYPES_MAP = {
  "ANEXA": ["anexa", "anex", "anx", "a"],
  "COD": ["codul", "cod", "c"],
  "CONTRACT": ["contractul", "contract", "contr", "cont", "cnt", "ctr", "ct", "con"],
  "LEGE": ["legile", "legi", "legea", "lege", "leg", "lg", "l"],
  "DECRET-LEGE": ["decret-lege", "decr-lege", "decret lege", "decretlege", "dlege", "decretl", "dleg", "decleg", "decl", "dl"],
  "DECRET": ["decretul", "decret", "decr", "dec", "d"],
  "DECIZIE": ["decizie", "dcz", "dz"],
  "DISPOZITIE": ["dispozitie", "dsp", "disp", "dpz", "directive", "directiva"],
  "HOTARARE GUVERN": ["hotarare de guvern", "hotararedeguvern", "hotarare guvern", "hotarareguvern", "hguv", "hotguv", "hotg", "hg"],
  "HOTARARE": ["hotarare", "hotarari", "hot", "h"],
  "LISTA": ["lista", "lst", "ls"],
  "MODEL DE CONTRACT": ["contract model", "model de contract", "modeldecontract", "model contract", "modelcontract", "modelc", "mdlc", "mc"],
  "ORDONANTA DE URGENTA": ["oug", "o.u.g", "ordurg", "ordonantadeurgenta", "ordonanta de urgenta", "ordonantaurgenta", "ordonanta urgenta", "o u g"],
  "ORDONANTA": ["ordguvern", "ord guvern", "ord guv", "ordonantadeguvern", "ordonanta de guvern", "ordonantaguvern", "ordonanta guvern", "o.g.", "og", "ordonanta", "o"],
  "ORDIN ADMINISTRATIE PUBLICA": ["oapub", "oap", "o.a.p.", "ordadmpub", "ordinadministratiepublica", "ordin administratie publica", "ordin de administratie publica", "o a p"],
  "ORDIN": ["ordin"],
  "PROCEDURA": ["procedura", "proced", "proc", "pr", "p"],
  "REGULAMENT": ["regulamentul", "regulament", "regul", "regl", "rg", "reg", "r"],
  "REGISTRU-JURNAL": ["regjurn", "rj", "registru-jurnal"],
  "SPETE": ["spete", "spt", "sp", "s"],
  "TABEL": ["tbl", "tabel", "tab", "t"],
  "ACT ADITIONAL": ["actaditional", "actad", "act aditional"],
  "ACT PROPRIU": ["actpropriu", "actprop", "act propriu"],
}

export const PUBLISHERS_MAP = {
  // "Lex": ["lex"],
  "Jurnalul Oficial": ["jurnalul oficial"],
  "Instanta de judecata": ["instanta de judecata"],
  "Monitorul Oficial Partea V-a": ["monitorul oficial partea a v-a", "monitorul oficial partea a 5-a", "monitorul oficial partea v-a", "monitorul oficial partea 5-a", "monitorul oficial partea v", "monitorul oficial partea 5"],
  "Monitorul Oficial": ["monitoarele oficiale", "monitoruloficial", "monitorul oficial", "monitor of.", "monitor oficial", "monitor of", "mon.of.", "mon.of", "mon of", "m of", "mof", "m. of", "m.of", "m. of.", "m.of.", "monitorul", "monitor", "mon", "m", "mo"],
}
export const allNewTypesUpper = [
  "ACT ADITIONAL",
  "ACT PROPRIU",
  "ADRESA",
  "ANEXA",
  "CERERE",
  "CIRCULARA",
  "COD",
  "COMPLETARI",
  "COMUNICAT",
  "CONSTITUTIA",
  "MODEL DE CONTRACT",
  "CONTRACT",
  "CONVENTIE",
  "CRITERII",
  "DECIZIE",
  "DECLARATIE",
  "DECRET",
  "DECRET-LEGE",
  "DISPOZITIE",
  "HOTARARE GUVERN",
  "HOTARARE",
  "INDRUMARI METODOLOGICE",
  "INSTRUCTIUNI",
  "LEGE",
  "LISTA",
  "MESAJ",
  "METODOLOGIE",
  "MODIFICARI SI COMPLETARI",
  "MOTIUNE",
  "NORMA",
  "NORME METODOLOGICE",
  "NORME TEHNICE",
  "NOTA INTERNA",
  "ORDIN AGENT. NAT. DE PRIVATIZARE",
  "ORDIN ADMINISTRATIE PUBLICA",
  "ORDIN",
  "ORDONANTA DE URGENTA",
  "ORDONANTA",
  "PRECIZARI",
  "PROCEDURA",
  "PROCES-VERBAL",
  "PROIECT DE LEGE",
  "PROTOCOL",
  "REGISTRU-JURNAL",
  "REGULAMENT",
  "REZOLUTIE",
  "SPETE",
  "STATUT",
  "TABEL",
  "TRANSPUNERE CONTURI"]
const allNewTypes = allNewTypesUpper.map(t => t.toLowerCase())

let allTypeKeywords = allNewTypes.slice(0)

allNewTypesUpper.forEach(t => {
  if (!TYPES2SHORT_TYPES_MAP[t]) {
    TYPES2SHORT_TYPES_MAP[t] = [t.toLowerCase().replace(/[ -.]/g, ''), t.toLowerCase()]
  }
  if (-1 === TYPES2SHORT_TYPES_MAP[t].indexOf(t.toLowerCase())) {
    TYPES2SHORT_TYPES_MAP[t].push(t.toLowerCase().replace(/[ -.]/g, ''), t.toLowerCase())
  }
  allTypeKeywords.push(...TYPES2SHORT_TYPES_MAP[t])
})

allTypeKeywords = _.uniq(allTypeKeywords)

function shortTipToRealTip(tipShort) {
  for (const tip in TYPES2SHORT_TYPES_MAP) {
    if (-1 !== TYPES2SHORT_TYPES_MAP[tip].indexOf(tipShort)) {

      return tip
    }
    if (-1 !== allNewTypes.indexOf(tipShort)) {
      const fullTip = allNewTypes[allNewTypes.indexOf(tipShort)]
      return fullTip.toUpperCase()
    }
  }
  return undefined
}

function shortPublisherToRealPublisher(pubShort) {
  for (const pub in PUBLISHERS_MAP) {
    if (-1 !== PUBLISHERS_MAP[pub].indexOf(pubShort)) {

      return pub
    }
  }
  return undefined
}

export function searchText2searchParams(searchText) {
  // console.log(TYPES2SHORT_TYPES_MAP)
  const allkeywords = allTypeKeywords
    // Object.keys(TYPES2SHORT_TYPES_MAP)
    // .reduce((p, c) => { p.push(...TYPES2SHORT_TYPES_MAP[c]); return p }, [])
    .concat(allNewTypes)
    .concat(Object.keys(PUBLISHERS_MAP).reduce((p, c) => { p.push(...PUBLISHERS_MAP[c]); return p }, []))
  // console.log("KWS", allkeywords)
  const normalizedSearchText = searchText.replace(/ +/g, ' ')
  const issuerMatches = normalizedSearchText.match(/emitenti:\{[^}]*?\}/mgi)
  const domainMatches = normalizedSearchText.match(/tematici:\{[^}]*?\}/mgi)

  let remainingSearchText = normalizedSearchText
  let issuers = null
  if (issuerMatches) {
    issuers = issuerMatches.map(m => m.replace(/emitenti:\{([^}]*?)\}/, '$1').split('/').map(el => el.trim())).reduce((p, c) => { p.push(...c); return p; }, [])
    issuerMatches.forEach(m => {
      remainingSearchText = remainingSearchText.replace(m, '')
    })
  }
  let domains = null
  if (domainMatches) {
    domains = domainMatches.map(m => m.replace(/tematici:\{([^}]*?)\}/, '$1').split('/').map(el => el.trim())).reduce((p, c) => { p.push(...c); return p; }, [])
    domainMatches.forEach(m => {
      remainingSearchText = remainingSearchText.replace(m, '')
    })
  }

  const keywordsRex = new RegExp(`(?:^| )(${allkeywords.join('|').replace(/\./g, '\\.')})(?:\\s*)((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?(\\/?\\d{4})?(?=$| )`, 'gi')

  /*
  (?:^| ) - start of line or space (non-capturing)

  (allkeywords.join ...) - any ONE keyword

  (?:\\s*) - whitespace zero or unlimited times (non-capturing)

  ((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?

    - ? at the end : zero or one times, as many as possible (greedy)
    - (?:nr|nr\\.|numarul|numaru)? : non-capturing
      - ? at the end : zero or one times, as many as possible (greedy)
      - nr sau nr. sau numarul sau numaru
    - (?:\\s*) - whitespace - zero or unlimited times (non-capturing)
    - \\d* - zero or unlimited digits
    - (i.e. "nr 123", "nr123", "nr.123")

  (\\/?\\d{4})?
    - ? at the end : zero or one times, as many as possible (greedy)
    - \\/? - zero or one / character
    - \\d{4} - 4 digits
    - (i.e. "/23")

  (?=$| )
    - positive look-ahead
    - either end of line or space

  */


  const keywordMatches = (remainingSearchText.match(keywordsRex) || []).map(m => m.trim())
  // console.log(keywordsRex)
  keywordMatches.forEach(km => {
    remainingSearchText = remainingSearchText.replace(km, ' ').replace(/\s+/g, ' ').trim()
  })
  // console.log(keywordsRex)
  // console.log({ remainingSearchText, normalizedSearchText, keywordMatches })

  const singleNrAnMatches = remainingSearchText.match(/(\d+)\/(\d{4})/g)
  if (singleNrAnMatches) {
    keywordMatches.push(...singleNrAnMatches)
    singleNrAnMatches.forEach(m => {
      remainingSearchText = remainingSearchText.replace(m, '')
    })
  }

  // const singleAnMatches = remainingSearchText.match(/(\d+)\/(\d{4})/g)
  // if (singleNrAnMatches) {
  //   keywordMatches.push(...singleNrAnMatches)
  // }

  let replacedSearchText = remainingSearchText.toLowerCase()

  //filter out specific title/text queries

  const titleMatches = replacedSearchText.match(/\{[^}]*?\}/mg)
  const textMatches = replacedSearchText.match(/\[[^\]]*?\]/mg)

  let title = '', text = ''

  if (titleMatches) {
    title = titleMatches.map(tm => tm.replace(/\{([^}]*)\}/, '$1')).join(' ')
  }
  if (textMatches) {
    text = textMatches.map(tm => tm.replace(/\[([^\]]*)\]/, '$1')).join(' ')
  }


  replacedSearchText = replacedSearchText
    .replace(/\{[^}]*?\}/g, '')
    .replace(/\[[^}]*?\]/g, '')
    .trim()

  let both = replacedSearchText || ''
  // console.log({ title, text, both })

  let smartParams = []

  keywordMatches.forEach(km => {

    const submatches = km.toLowerCase().replace(/(?:\s+(?:nr|nr\.|numarul|numaru)\s*)(\d)/, ' $1').trim().match(/([a-zA-Z. -]*)(?:\s*)(\d*)?\/?(\d{4})?/i)
    // console.log(submatches)
    const tip = shortTipToRealTip(submatches[1].trim().toLowerCase())
    const pub = shortPublisherToRealPublisher(submatches[1].trim().toLowerCase())
    const matched_nr = submatches[2] || undefined
    const matched_an = submatches[3] || undefined
    let an, nr
    if (matched_nr && !matched_an && parseInt(matched_nr, 10) >= 1900 && parseInt(matched_nr, 10) < 2050) {
      an = matched_nr
    } else {
      an = matched_an
      nr = matched_nr
    }
    smartParams.push({ tip, pub, nr, an })
    // if (tip === 'COD') {
    //   both = 'codul ' + both
    //   smartParams.push({ tip: "LEGE", pub, nr, an })
    // }
  })

  smartParams.forEach(sp => {
    if (sp.tip === "ORDIN") {
      smartParams.push({ ...sp, tip: "ORDIN ADMINISTRATIE PUBLICA" })
    }
  })

  // console.log({ searchText, both, title, text, smartParams, issuers, domains })
  return { searchText, both, title, text, smartParams, issuers, domains }
}

export function advSearchParams2searchText(params) {

  const { title, text, both, typeTags, domainTags, issuerTags, actYear, monYear, actNumber, monNumber } = params

  let parts = []
  if (title || text || both) {
    if (title === text === both)
      parts.push(title)
    else {
      parts.push([title ? `{${title}}` : '', text ? `[${text}]` : '', both].filter(Boolean).join(' '))
    }
  }

  if (typeTags && typeTags.length) {
    typeTags.forEach(tt => {
      // const mapped = TYPES2SHORT_TYPES_MAP[tt]
      // , type = mapped ? mapped[0].toUpperCase() : type
      parts.push([tt, [`${actNumber ? actNumber : ''}`, `${actYear ? '/' + actYear : ''}`].filter(Boolean).join('')].filter(Boolean).join(' '))
    })
  } else if (actNumber || actYear) {
    parts.push([`${actNumber ? actNumber : ''}`, `${actYear ? '/' + actYear : ''}`].filter(Boolean).join(''))
  }

  if (monNumber || monYear)
    parts.push('Monitorul oficial ' + [`${monNumber ? monNumber : ''}`, `${monYear ? '/' + monYear : ''}`].filter(Boolean).join(''))

  if (issuerTags && issuerTags.length) {
    parts.push(`emitenti:{${issuerTags.join('/')}}`)
  }

  if (domainTags && domainTags.length) {
    parts.push(`tematici:{${domainTags.join('/')}}`)
  }

  const searchText = parts.join(' ')
  return searchText
}

export function smartSearchItem2NiceDescription(smartSearchItem) {
  const { tip, an, nr } = smartSearchItem
  return (tip ? tip === 'Monitorul oficial' ? 'Monitorul oficial' : tip : 'Toate documentele') + (nr ? ` cu nr ${nr}` : ' cu orice numar') + (an ? ` din anul ${an}` : 'din orice an')
}

export function searchParams2searchText(params) {
  const textSearchParams = params.text;
  if (textSearchParams) {
    console.log(textSearchParams);
    return textSearchParams.filter(i => i.type && i.type.toLowerCase() === 'text').map(i => i.value).join(' ')
  }
  return ''
}

export function searchText2searchParamsNew(searchText) {

  const searchParams = {}

  if (searchText.toLowerCase().trim().startsWith('dosar')) {
    searchParams.isCaseSearch = true;
    const normalizedSearchText = searchText
      .replace(/ +/g, ' ')
      .trim()

    const numberRex = /(?:^| )Dosar {0,1}([0-9]+[^\s|$]+)(?=$| )/i
    const objectRex = /\[[^\]]+\]/gi
    const partRex = /\([^\]]+\)/gi
    const institutionsRex = /{[^}]+}/gi

    const numberMatch = normalizedSearchText.match(numberRex)

    console.log(normalizedSearchText)
    console.log(numberRex)
    console.log(numberMatch)

    if (numberMatch) {
      searchParams.number = numberMatch[1]
    }

    let remainingSearchText = normalizedSearchText.replace(numberRex, '');

    const objectMatch = remainingSearchText.match(objectRex);

    if (objectMatch) {
      searchParams.object = objectMatch[0].replace(/^\[/, '').replace(/\]$/, '')
    }

    remainingSearchText = remainingSearchText.replace(objectRex, '');

    const partMatch = remainingSearchText.match(partRex);

    if (partMatch) {
      searchParams.part = partMatch[0].replace(/^\(/, '').replace(/\)$/, '')
    }

    remainingSearchText = remainingSearchText.replace(partRex, '');

    const institutionsMatch = remainingSearchText.match(institutionsRex);

    if (institutionsMatch) {
      searchParams.institutions = institutionsMatch[0]
        .replace(/^\{/, '')
        .replace(/\}$/, '')
        .split(',')
        .map(i => ({ value: i.trim() }))
    }

  } else {

    const publisherKeywords = Object.keys(PUBLISHERS_MAP).reduce((p, c) => { p.push(...PUBLISHERS_MAP[c]); return p }, [])

    const allkeywords = allTypeKeywords.concat(publisherKeywords)

    const keywordsRex = new RegExp(`(?:^| )(${allkeywords.join('|').replace(/\./g, '\\.')})?(?:\\s*)((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?(?:[\\/ ]?)(\\d{4})?(?=$| )`, 'gi')
    const tipRex = new RegExp(`(?:^| )(${allTypeKeywords.join('|').replace(/\./g, '\\.')})(?:\\s*)((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?(?:[\\/ ]?)(\\d{4})?(?=$| )`, 'gi')
    const pubRex = new RegExp(`(?:^| )(${publisherKeywords.join('|').replace(/\./g, '\\.')})(?:\\s*)((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?(?:[\\/ ]?)(\\d{4})?(?=$| )`, 'gi')

    const normalizedSearchText = searchText.replace(/ +/g, ' ')

    let remainingSearchText = normalizedSearchText

    const keywordMatches = (normalizedSearchText.match(keywordsRex) || []).map(m => m.trim())

    keywordMatches.forEach(keywordMatch => {

      let found = false;

      const tipMatch = tipRex.exec(keywordMatch);

      if (tipMatch) {
        found = true

        const [, tip, number, year] = tipMatch
        searchParams.type = searchParams.type
          ? [...searchParams.type, { value: shortTipToRealTip(tip.toLowerCase()) }]
          : [{ value: shortTipToRealTip(tip.toLowerCase()) }]
        if (number) searchParams.id = number
        if (year) searchParams.year = year
      }

      const pubMatch = pubRex.exec(keywordMatch);

      if (pubMatch) {
        found = true
        const [, pub, number, year] = pubMatch

        searchParams.publishers = searchParams.publishers
          ? [...searchParams.publishers, { value: shortPublisherToRealPublisher(pub.toLowerCase()) }]
          : [{ value: shortPublisherToRealPublisher(pub.toLowerCase()) }]
        if (number) searchParams.id_mof = number
        if (year) searchParams.year_mof = year
      }

      if (!found) {
        const numberMatch = keywordsRex.exec(keywordMatch);

        const [, , number, year] = numberMatch
        if (number) searchParams.id = number
        if (year) searchParams.year = year
      }

      remainingSearchText = remainingSearchText
        .replace(keywordMatch, ' ')
        .replace(/\s+/g, ' ')
        .trim()
    })

    const titleRex = /{[^}]+}/gi
    const textRex = /\[[^\]]+\]/gi

    const titleMatches = (remainingSearchText.match(titleRex) || []).map(m => m.trim());

    searchParams.text = []

    titleMatches.forEach(titleMatch => {
      const textParts = titleMatch.replace(/^{/, '').replace(/}$/, '').split(' ')

      searchParams.text.push(...textParts.map(part => ({
        type: 'Titlu',
        value: part
      })))

      remainingSearchText = remainingSearchText
        .replace(titleMatch, ' ')
        .replace(/\s+/g, ' ')
        .trim()
    })

    const textMatches = (remainingSearchText.match(textRex) || []).map(m => m.trim());

    textMatches.forEach(textMatch => {
      const textParts = textMatch.replace(/^\[/, '').replace(/]$/, '').split(' ')

      searchParams.text.push(...textParts.map(part => ({
        type: 'Text',
        value: part
      })))

      remainingSearchText = remainingSearchText
        .replace(textMatch, ' ')
        .replace(/\s+/g, ' ')
        .trim()
    })

    if (remainingSearchText) {
      searchParams.text = remainingSearchText
        .split(' ')
        .map(entry => ({
          type: 'Titlu și text',
          value: entry
        }))
    }

    if (searchParams.type && searchParams.type.find(t => t.value.toUpperCase() === 'ORDIN') && !searchParams.type.find(t => t.value.toUpperCase() === 'ORDIN ADMINISTRATIE PUBLICA')) {
      searchParams.type.push({
        value: 'ORDIN ADMINISTRATIE PUBLICA'
      })
    }
  }

  return searchParams
}

export function searchParams2searchTextNewDocuments(searchParams) {
  const { type, publishers, issuers, text, id, id_mof, year, year_mof } = searchParams;

  let numbersAdded = false;
  const searchTextParts = []

  if (type && type.length) {
    numbersAdded = true;
    type.forEach(t => {
      if (id && year) {
        searchTextParts.push(`${t.value} ${id}/${year}`)
      } else {
        searchTextParts.push(`${t.value}`)
      }
    })
  }

  if (issuers && issuers.length) {
    numbersAdded = true;
    issuers.forEach(t => {
      if (id_mof && year_mof) {
        searchTextParts.push(`${t.value} ${id_mof}/${year_mof}`)
      } else {
        searchTextParts.push(`${t.value}`)
      }
    })
  }

  if (publishers && publishers.length) {
    numbersAdded = true;
    publishers.forEach(t => {
      if (id_mof && year_mof) {
        searchTextParts.push(`${t.value} ${id_mof}/${year_mof}`)
      } else {
        searchTextParts.push(`${t.value}`)
      }
    })
  }

  if (text && text.length) {
    const onlyText = []
    const onlyTitle = []
    const both = []
    text.forEach(t => {
      if (t.type) {
        switch (t.type.toLowerCase()) {
          case 'text':
            onlyText.push(t.value);
            break;
          case 'titlu':
            onlyTitle.push(t.value);
            break;
          default:
            both.push(t.value)
        }
      } else {
        both.push(t.value)
      }
    })

    if (onlyTitle.length) searchTextParts.push(`{${onlyTitle.join(' ')}}`)
    if (onlyText.length) searchTextParts.push(`[${onlyText.join(' ')}]`)
    if (both.length) searchTextParts.push(...both)
  }

  if (!numbersAdded) {
    if (id && year) searchTextParts.push(`${id}/${year}`)
    if (id_mof && year_mof) searchTextParts.push(`${id_mof}/${year_mof}`)
  }

  return searchTextParts.join(' ').trim()
}

export function searchParams2searchTextNewCases(searchParams) {
  const { number, object, part, institutions } = searchParams;

  const searchTextParts = [];

  if (number) {
    searchTextParts.push(number);
  }

  if (object) {
    searchTextParts.push(`[${object}]`)
  }

  if (part) {
    searchTextParts.push(`(${part})`);
  }

  if (institutions) {
    searchTextParts.push(`{${institutions.map(i => i.value).join(', ')}}`)
  }

  return `DOSAR ${searchTextParts.join(' ').trim()}`
}

export function searchParams2searchTextNew(searchParams, isCaseSearch) {
  return isCaseSearch
    ? searchParams2searchTextNewCases(searchParams)
    : searchParams2searchTextNewDocuments(searchParams)
}


export { ORDERED_TYPES }
