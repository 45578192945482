import React, { useCallback, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { sanitizeAndHighlight } from '../lib/textutils'
import { PUBLISHERS_MAP } from '../lib/searchutils'
import { stringDateWithoutTime } from '../lib/dateutils';
import Spinner from './Spinner'
import { UserContext } from '../context'
import { SearchContext } from '../contexts/SearchContext'
import "../styles/search-results.scss";
import { isMobile } from '../customhooks/useMobile';


const More = (props) => props.hasMore
  ? <button className="btn btn-block btn-light my-4" onClick={props.onClick}>
    {
      props.searching
        ? <i className="fa fa-refresh fa-spin fa-fw"></i>
        : null
    }
    {
      !props.searching
        ? <span>Mai multe... <i className="fa fa-angle-double-down"></i></span>
        : <span>Se incarca...</span>
    }
  </button>
  : null


const SearchResult = (props) => {
  const {
    id,
    score,
    newtype: type,
    title,
    deleted,
    publish_nr,
    publish_year,
    publisher_name,
    // type_name: oldtype,
    status_name,
    status_code,
    issue_year,
    issuers,
    domains,
    // revdata,
    tracked,
    // end_date,
    highlight
  } = props.result
  const { showTextMatches, index } = props

  const { searchText, overrideSearchParams } = useContext(SearchContext);

  const highlightedTitle = highlight && highlight.title && highlight.title.length ? sanitizeAndHighlight(highlight.title[0]) : title
  const highlightedText = showTextMatches && highlight && highlight.html ? highlight.html.map(html => `<div class="mt-0 px-1 lex-result-text fs-11">${sanitizeAndHighlight(html)}</div>`).join('') : null
  const shortPublisherName = publisher_name.replace(/Monitorul oficial/i, 'M.Of.')
  const searchTextPathPart = searchText ? "/" + encodeURIComponent(searchText) : ""
  const publishersThatCanBeLinked = Object.keys(PUBLISHERS_MAP)
  const publisher = -1 !== publishersThatCanBeLinked.indexOf(publisher_name) ?
    <Link
      to={`/search`}
      onClick={() => overrideSearchParams({
        id_mof: publish_nr,
        year_mof: publish_year
      })}
    >
      {shortPublisherName}<br />{publish_nr}/{publish_year}
    </Link>
    :
    <span>{shortPublisherName}<br /></span>
  // <span>{shortPublisherName} <br /> {publish_nr}/{publish_year}</span>

  // console.log("SR searchParams", searchParams)
  return (
    <div className="d-flex justify-content-between align-items-center mb-1 search-result">
      <div className="align-self-start mr-1 text-muted fs-9" style={{ marginTop: '4px' }}>{index}.</div>
      <div style={{ flexGrow: 1 }} className="d-flex justify-content-between align-items-center result-wrap p-1">
        <div className="d-flex flex-column align-items-strech">
          <h5 className="mb-0 d-flex flex-column">
            <div>
              <Link
                // to={{ pathname: `/view/${id}${searchTextPathPart}` }}
                to={{ pathname: `/view/${id}` }}
                className="" target="_self"
              // onClick={() => overrideSearchParams({})}
              >
                <span className="type-label mr-1">{type}</span>
                <span className="issuer-label mr-1">{`${issuers[0].issuer_name}`}</span><span style={{ fontSize: 12, fontWeight: "bold", color: "black" }}>nr. </span><span className="issue-nr-label">{`${issuers[0].issuer_nr}/${issue_year}`}</span>
              </Link>
              {deleted && <span className="small font-italic" style={{ color: "red" }}> STERS</span>}
            </div>
            <Link
              // to={{ pathname: `/view/${id}${searchTextPathPart}` }}
              to={{ pathname: `/view/${id}` }}
              className="title-label"
              dangerouslySetInnerHTML={{ __html: highlightedTitle }}
              target="_self"
            // onClick={() => overrideSearchParams({})}
            />
            <div className="fs-12 pt-1">
              <span className="text-muted">Tematici:</span> <b>{domains.map(d => d.domain_name).join(', ')}</b>
            </div>
            <div className="status-label pt-1">
              <span className="text-muted">Stare act:</span> <b className={`status status-${status_code}`}>{status_name}</b>
            </div>
          </h5>
          <div className="small text-muted font-italic mt-1" dangerouslySetInnerHTML={{ __html: highlightedText }} />
        </div>
        <div className="ml-auto pl-4 pr-0 text-center lex-sr-monitor-right text-muted fs-12 h-100 d-flex flex-column justify-content-center align-items-center">
          {publisher}
          <div>
            <div className={`btn btn-outline-${tracked ? "primary" : "metallic-light"} align-self-flex-end btn-sm`} style={{ border: 0 }} onClick={() => props.toggleTracked(props.result)}><i className="fa fa-folder-open fs-12"></i></div>
          </div>
        </div>
      </div>
      {score ? <span className="lex-score">{score}</span> : null}
    </div>

  )
}

const CaseSearchResult = (props) => {
  const {
    number = '-',
    date = '-',
    lastUpdate = '-',
    object = '-',
    status = '-',
    division = '-',
    institution = '-',
    parts,
  } = props.result
  const { index } = props;

  const mobile = isMobile();

  // console.log(props.result)
  return (
    <div className='search-result-new-case'>
      {
        !mobile
          ? <div className='search-result-new-case-cell x-small'>
            {index}
          </div>
          : null
      }

      <Link
        // to={{ pathname: `/view/${id}${searchTextPathPart}` }}
        to={{ pathname: `/viewCase/${encodeURIComponent(number)}/${encodeURIComponent(institution)}` }}
        className={`search-result-new-case-cell link-dosar small break-word ${mobile ? 'd-flex flex-row justify-content-between' : ''}`}
        target="_self"
      // onClick={() => overrideSearchParams({})}
      >
        {mobile ? 'Dosar nr.: ' : null}{number} {mobile ? <span className='me-auto'>{index}</span> : null}
      </Link>
      <div className='search-result-new-case-cell small'>
        {mobile ? <span className='strong'>Data: </span> : null}{stringDateWithoutTime(date)}
      </div>
      <div className='search-result-new-case-cell large'>
        {mobile ? <span className='strong'>Obiect: </span> : null}{object}
      </div>
      <div className='search-result-new-case-cell'>
        {mobile ? <span className='strong'>Stadiul procedural: </span> : null}{status}
      </div>
      <div className='search-result-new-case-cell small'>
        {mobile ? <span className='strong'>Secţie: </span> : null}{division}
      </div>
      <div className='search-result-new-case-cell small'>
        {mobile ? <span className='strong'>Instituţie: </span> : null}{institution}
      </div>
      <div className='search-result-new-case-cell large'>
        {mobile ? <span className='strong'>Părţi: </span> : null}
        {
          parts && parts.length
            ? parts.map((part, idx) => <div className='w-100 mb-1' key={idx}>{part}</div>)
            : '-'
        }
      </div>
    </div>

  )
}

const SearchResults = ({
  total,
  took,
  results,
  searching,
  displayAsCases = false,
  onLoadMore,
  toggleTracked,
}) => {
  const { loggedInUser } = useContext(UserContext)
  const { isCaseSearch } = useContext(SearchContext)

  const mobile = isMobile();

  const checkScroll = useCallback(() => {
    const { clientHeight, scrollHeight } = document.scrollingElement
      , { scrollY } = window
      , checkPoint = clientHeight + scrollY
    // console.log("SCROLL", { checkPoint, scrollY, scrollTop, clientHeight, scrollHeight })
    if (checkPoint === scrollHeight) {
      onLoadMore()
    }
  }, [onLoadMore])

  useEffect(() => {
    window.addEventListener('scroll', checkScroll)
    return () => {
      // const resultsElement = document.getElementById('infiniteScroll')
      // resultsElement.removeEventListener('scroll', checkScroll)
      window.removeEventListener('scroll', checkScroll)
    }
  }, [checkScroll])


  const { showTextMatchesInSearchResults } = loggedInUser.prefs.settings
  const hasMore = results ? total - results.length > 0 : false
  return (
    <>
      <div className="container" style={{ paddingBottom: '1rem' }}>
        {/* <hr className="w-100" /> */}
        <div className="col-12 d-flex justify-content-center lex-cases-pad">
          <Spinner className="my-2 small" spin={searching} />
          {
            !searching && took
              ? <span className="text-muted font-italic small my-2">
                S-au gasit <strong>{total}</strong> rezultate in {(took / 1000)}s
              </span>
              : null
          }
        </div>
        { // Table header (only for cases) (only on desktop)
          results && results.length && isCaseSearch && !mobile
            ?
            <div className='col-12 search-result-new-case-header-wrapper'>
              <div className='search-result-new-case header'>
                <div className='search-result-new-case-cell x-small' />
                <div className='search-result-new-case-cell small'>
                  Număr
                </div>
                <div className='search-result-new-case-cell small'>
                  Data
                </div>
                <div className='search-result-new-case-cell large'>
                  Obiect
                </div>
                <div className='search-result-new-case-cell'>
                  Stadiul procesual
                </div>
                <div className='search-result-new-case-cell small'>
                  Secţie
                </div>
                <div className='search-result-new-case-cell small'>
                  Instituţie
                </div>
                <div className='search-result-new-case-cell large'>
                  Părţi
                </div>
              </div>
            </div>
            : null
        }
        {
          !results
            ? null
            :
            <div className="col-12 search-result-list">
              {
                results.map((r, i) => {
                  return (
                    isCaseSearch
                      ? <CaseSearchResult
                        key={'result-' + i}
                        index={i + 1}
                        result={r}
                        toggleTracked={toggleTracked}
                      />
                      : <SearchResult
                        key={'result-' + i}
                        index={i + 1}
                        result={r}
                        showTextMatches={showTextMatchesInSearchResults}
                        toggleTracked={toggleTracked}
                      />

                  )
                })
              }
            </div>
        }
        {
          !isCaseSearch
            ? <More hasMore={hasMore} searching={searching} onClick={onLoadMore} />
            : null
        }
      </div>
    </>
  )
}

export default SearchResults
