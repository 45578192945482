import React from 'react'
import * as dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import SidePanel from './fragments/SidePanel'
import { date2DDMMMYYYString } from './lib/textutils'
import { isMobile } from './customhooks/useMobile'

const HistorySidePanel = (props) => {
  const mobile = isMobile();
  const { doc } = props
  return !doc ? null : (
    <SidePanel
      id="history-sidepanel"
      tooltip='Istoric act compus'
      title={<i className="fa fa-history"></i>}
      top={mobile ? 5 : 300}
      width={350}
      btnclass="btn-metallic-light"
      topTitleComponent={<div className="panel-title w-100 fs-16 pl-2"><i className="fa fa-history"></i> Istoric - Act Compus</div>}
    >
      <table className="table table-hover">
        <tbody>
          {doc.revisions
            .sort((a, b) => {
              const aDate = dayjs(a.rev_date)
              const bDate = dayjs(b.rev_date)
              return bDate.unix() - aDate.unix()
            })
            .map((r, i) => {
              const date = new Date(r.rev_date)
                , df = date2DDMMMYYYString(date)
                , badgeClass = r.rev_type === 'Publicare' || r.rev_type === 'Republicare' ? 'badge badge-pill badge-success' : 'badge badge-pill badge-info ml-1'
              return (
                <tr key={`rev-${r.id}`} style={{ backgroundColor: r.id === doc.docid || r.lexid === doc.lexid ? '#ddd' : undefined }}>
                  <td>
                    <span className={badgeClass}>{r.rev_type}</span>
                  </td>
                  <td className="review-link">
                    <Link to={`/view/${r.id}`} style={{ color: r.deleted ? 'red' : 'inherit', fontStyle: r.deleted ? 'italic' : 'normal' }}>{df}</Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </SidePanel>
  )
}

export default HistorySidePanel
